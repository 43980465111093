import defaultTheme from './default';
import { CATEGORY } from './constants';

const brandColors = {
  // base colors
  blue: 'rgb(0, 90, 225)',
  black: '#000000',
  // accent colors
  red: 'rgb(255, 25, 68)',
  lightGreen: 'rgb(105, 238, 20)',
  orange: 'rgb(255, 108, 20)',
  violet: 'rgb(172, 0, 250)',
  lightBlue: 'rgb(0, 215, 250)',
  yellow: 'rgb(255, 205, 28)',
  deepBlue: 'rgb(45, 0, 250)',
};

export default {
  ...defaultTheme,
  name: 'Tetris 99',
  slug: 'tetris',
  category: CATEGORY.IP,
  description:
    'All designs using Tetris assets need to be sent to TETRIS Company for approvals.',
  color: {
    ...defaultTheme.color,
    primary: brandColors.blue,
    primaryHover: brandColors.deepBlue,
    primaryLight: brandColors.lightBlue,
    secondary: brandColors.red,
    secondaryHover: brandColors.violet,
    secondaryLight: brandColors.yellow,
  },
};
