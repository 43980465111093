import { useRef } from 'react';
import { arrayOf, object } from 'prop-types';
import { useScroll, motion, useTransform } from 'framer-motion';
import { RichText } from '@local/components';
import { Image } from '@nintendo-of-america/component-library';
import { Switch2Modal } from '@local/components/pages/GamingSystems/Switch2';
import clsx from 'clsx';
import s from './Switch2Tabletop.module.css';
const Switch2Tabletop = ({ section, modalPage }) => {
  const tableTopRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: tableTopRef });
  const y1 = useTransform(scrollYProgress, [0, 0.7], ['15%', '0%']);
  const o1 = useTransform(scrollYProgress, [0, 0.7], ['0%', '100%']);
  return (
    <section
      id={section.deepLink}
      className={clsx(section.hide && s.hide, s.container)}
      ref={tableTopRef}
    >
      <div className={s.stickyContainer}>
        <motion.div className={s.tabletopText} style={{ y: y1, opacity: o1 }}>
          <div>
            <RichText data={section.modules[0].body} />
          </div>
          <div>
            <Switch2Modal
              page={modalPage}
              button={section.modules[1].ctas[0]}
              header={section.modules[1].body}
            />
          </div>
        </motion.div>
        <div className={s.floatBg}>
          <Image
            assetPath={section.modules[2].asset.primary.assetPath}
            alt={section.modules[2].asset.alt}
          />
        </div>
      </div>
    </section>
  );
};

Switch2Tabletop.propTypes = {
  section: arrayOf(object),
};

export default Switch2Tabletop;
