import {
  OverlinedTitle,
  LeftBorderTitle,
} from '@local/components/pages/GamingSystems/Switch2/';
import { Spacer, Image } from '@nintendo-of-america/component-library';
import s from './SecureChatSection.module.css';
import RichText from '@local/components/RichText';
import clsx from 'clsx';
import { MEDIA_TYPE, useMediaWidth } from '@local/lib/hooks';

const SecureChatSection = ({ module }) => {
  const modules = module?.modules;
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  return (
    <div>
      <Spacer size={48} />
      <div className={s.divider} />
      <OverlinedTitle gray text={module?.heading} />
      <p className={s.textSize}>
        <b>{modules?.[0]?.text}</b>
      </p>
      <div className={s.container}>
        {!isTablet && (
          <Image
            assetPath={modules?.[2]?.primary?.assetPath}
            alt={modules?.[2]?.alt}
          />
        )}
        <div className={s.card}>
          <LeftBorderTitle heading={modules?.[1]?.heading} />
          <RichText
            className={clsx(s.textSize, s.textAlign)}
            data={modules?.[1]?.body}
          />
        </div>
        {isTablet && (
          <Image
            assetPath={modules?.[2]?.primary?.assetPath}
            alt={modules?.[2]?.alt}
          />
        )}
        {modules?.slice(3).map((item) => (
          <div className={s.card} key={item?.heading}>
            <LeftBorderTitle heading={item?.heading} />
            <RichText
              className={clsx(s.textSize, s.textAlign)}
              data={item?.body}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecureChatSection;
