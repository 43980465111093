import PeonBase from '../PeonBase';

function MultiContentSection({ section, productProps }) {
  return <PeonBase section={section} productProps={productProps} />;
}

MultiContentSection.bluePrint = [
  { peonPromo: 'Heading CTA' },
  { listPeonPromos: 'anyUp' },
  { listProducts: 'Product rail' },
  { listAssets: 'Asset Grid' },
  { listCtas: 'CTA grid' },
  { richText: '' },
  { youtubeVideo: '' },
  { ContentRating: '' },
];

export default MultiContentSection;
