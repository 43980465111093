import styled from 'styled-components';
import { CONSTRAINED_MAX_WIDTH_SMALL } from '@local/components/constrained';

export const PillContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({ $reverseContent }) =>
    $reverseContent ? 'left' : 'center'};
  flex-wrap: ${({ $reverseContent }) => ($reverseContent ? 'nowrap' : 'wrap')};
  overflow: ${({ $reverseContent }) =>
    $reverseContent ? 'scroll' : 'inherit'};
  max-width: ${CONSTRAINED_MAX_WIDTH_SMALL};
  margin: 0 auto;
  padding: 1rem 0;
  ${({ theme }) => theme.mediaQuery.tablet} {
    flex-wrap: wrap;
    justify-content: center;
    overflow: inherit;
  }
`;

export const ButtonContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
`;
