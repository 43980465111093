import {
  Spacer,
  NsoHorizontalLogoIcon,
} from '@nintendo-of-america/component-library';
import s from './NSOCard.module.css';
import { RichText } from '@local/components/';
import { useReducedMotion } from '@shared/util';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import clsx from 'clsx';

/**
 * NSO Card
 *
 * @param {Object} module - Module: Section object, containing modules, containing array of modules of rich text and text
 * @example
 * // example argument
 * const module = {
 *  modules: [
 *      {Rich Text object},
 *      {Text object},
 *      {Text object},
 *      {Text object},
 *      {Text object} <- text in circle
 *  ]
 * }
 */

const NSOCard = ({ module, noMinWidth = false, animationDisable }) => {
  const prefersReducedMotion = useReducedMotion();
  const animationProps =
    prefersReducedMotion || animationDisable
      ? {}
      : {
          initial: { scale: 0.8, opacity: 0.7 },
          whileInView: {
            scale: 1,
            opacity: 1,
            transition: {
              type: 'spring',
              duration: 0.2,
              stiffness: 250,
              delay: 0.1,
            },
          },
          viewport: { once: true, amount: 0.99 },
        };
  const modules = module?.modules;
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        {...animationProps}
        className={clsx(s.cardContainer, noMinWidth && s.noMinWidth)}
      >
        <div className={s.logoContainer}>
          <NsoHorizontalLogoIcon size="200px" />
        </div>
        <div className={s.text}>
          <RichText data={modules?.[0]?.richText} />
          <Spacer size={32} />
          <div className={s.innerWrap}>
            <div className={s.detailContainer}>
              <div className={s.circle}>{modules?.[4]?.text}</div>
              <div className={s.padding}>
                <div className={s.subheading}>
                  {modules?.[1]?.text} {modules?.[1]?.text && <br />}
                </div>
                <div className={s.accentText}>{modules?.[2]?.text}</div>
                <Spacer size={12} />
                {modules?.[3]?.text}
              </div>
            </div>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default NSOCard;
