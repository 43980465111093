import PeonBase from '../PeonBase';
import s from './MiniNso.module.css';
import { NSOCard } from '@local/components/pages/GamingSystems/Switch2';

function MiniNso({ section }) {
  return (
    <PeonBase
      section={section}
      peonPromo={(module) => {
        return (
          <div className={s.nsoContain}>
            <NSOCard
              animationDisable={section.modifiers.includes('animationDisable')}
              module={{
                modules: module.body
                  ? [
                      {
                        richText: {
                          ...module?.body,
                          content: [module?.body?.content[0]],
                        },
                      },
                      { text: module?.body?.content[1]?.content[0]?.value },
                      { text: module?.body?.content[2]?.content[0]?.value },
                      { text: module?.body?.content[3]?.content[0]?.value },
                      { text: module?.body?.content[4]?.content[0]?.value },
                    ]
                  : [],
              }}
            />
          </div>
        );
      }}
    />
  );
}

MiniNso.bluePrint = [
  { peonPromo: 'NSO Mini Blade, Enter up to 5 lines in rich text field' },
];

export default MiniNso;
