import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme, $scrim }) =>
    $scrim.dark
      ? theme.color.white
      : $scrim.light
        ? theme.color.black
        : 'inherit'};

  img {
    object-fit: contain;
    max-height: 400px;
    width: 100%;
  }
`;
