import { ThemeProvider } from '@nintendo-of-america/component-library';
import { Link } from '@nintendo-of-america/next';
import * as themes from '@nintendo-of-america/component-library/src/theme';

import * as S from './PeonWrapper.styles';

function PeonWrapper({ section, children, preview, bluePrint, fullWidth }) {
  const requiredModules =
    bluePrint?.filter((item) => Object.keys(item)[0].includes('*')).length ||
    null;
  const theme = {
    ...(themes[section?.theme] || themes?.defaultTheme),
    ProxyLink: Link,
  };
  const bottomDivider = section?.background?.bottomDivider;
  const paddingBottom =
    bottomDivider &&
    section.background?.dividerPosition !== 'top' &&
    bottomDivider[0]?.height;

  if (
    preview &&
    ((section.modules?.length == 0 && !section.background?.CONTENT_TYPE) ||
      (section.modules?.length == 0 && section.sectionType != '') ||
      section.modules?.length < requiredModules)
  ) {
    return (
      <S.BluePrintSection>
        <p>
          <strong>{section.sectionType} Section</strong>
        </p>
        <p>
          Preview mode is on, and this component is missing data. it can render:
        </p>
        {bluePrint.map((option, i) => (
          <S.Item
            $required={Object.keys(option)[0]?.includes('*')}
            key={'blueprint ' + i}
          >
            {Object.keys(option)}
            <br /> <span>{Object.values(option)}</span>
          </S.Item>
        ))}
      </S.BluePrintSection>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <S.PeonSection
          $background={section.background}
          id={section.deepLink}
          $hide={section.hide}
          $fullWidth={fullWidth}
          $paddingBottom={paddingBottom}
          $paddingAlt={section.modifiers?.includes('paddingAlt')}
          $textCenter={section.modifiers?.includes('textCenter')}
        >
          <S.ChildContainer $fullWidth={fullWidth}>{children}</S.ChildContainer>
          {bottomDivider && (
            <S.FloatDivider
              $imgDesktop={bottomDivider[0]?.image}
              $imgMobile={bottomDivider[1]?.image}
              $height={bottomDivider[0]?.height}
              $repeat={section.background.dividerRepeat}
              $position={section.background.dividerPosition}
            />
          )}
        </S.PeonSection>
      </ThemeProvider>
    );
  }
}

export default PeonWrapper;
