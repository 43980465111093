import { useState, useRef, useEffect } from 'react';
import { useMatchMedia } from '@nintendo-of-america/react-hooks';
import { arrayOf, object } from 'prop-types';
import clsx from 'clsx';
import {
  Switch2Video,
  Switch2Modal,
  FramedVideo,
} from '@local/components/pages/GamingSystems/Switch2';
import { RichText, ContentRating } from '@local/components';
import { Button, Image } from '@nintendo-of-america/component-library';
import {
  m,
  LazyMotion,
  useScroll,
  useTransform,
  useMotionValueEvent,
  domAnimation,
  AnimatePresence,
} from 'framer-motion';
import s from './Switch2Showcase.module.css';

const Switch2Showcase = ({ section, tvModePage, handheldPage }) => {
  const showcaseRef = useRef(null);
  const [isDocked, setIsDocked] = useState(false);
  const [switchXValue, setSwitchXValue] = useState('-50%');
  const [dockYValue, setdockYValue] = useState('100px');
  const [tvXValue, setTvXValue] = useState('-50%');
  const { scrollYProgress } = useScroll({ target: showcaseRef });
  const isDesktop = useMatchMedia('(min-width: 768px)');
  useEffect(() => {
    setSwitchXValue(isDesktop ? '-50%' : '0%');
    setdockYValue(isDesktop ? '100px' : '50px');
    setTvXValue(!isDesktop ? '0%' : '-50%');
  }, [isDesktop]);

  const s1 = useTransform(scrollYProgress, [0, 0.36, 0.47], [1, 1, 0.5]);
  const tvX = useTransform(
    scrollYProgress,
    [0, 0.36, 0.47],
    ['0%', '0%', tvXValue]
  );

  const switchX = useTransform(
    scrollYProgress,
    [0, 0.36, 0.47],
    ['0%', '0%', switchXValue]
  );

  const dockY = useTransform(
    scrollYProgress,
    [0.36, 0.47],
    ['0px', dockYValue]
  );
  const opac1 = useTransform(
    scrollYProgress,
    [0, 0.1, 0.15, 0.2],
    ['0%', '100%', '100%', '0%']
  );
  const headingO2 = useTransform(
    scrollYProgress,
    [0.2, 0.25, 0.3, 0.35],
    ['0%', '100%', '100%', '0%']
  );
  const opac2 = useTransform(
    scrollYProgress,
    [0.45, 0.55, 0.75, 0.84],
    ['0%', '100%', '100%', '0%']
  );
  const opacDock = useTransform(scrollYProgress, [0.2, 0.28], ['0%', '100%']);
  const y3 = useTransform(scrollYProgress, [0.7, 0.84], ['0', '-300px']);
  const o3 = useTransform(scrollYProgress, [0.7, 0.84], ['100%', '0%']);

  const k1 = useTransform(
    scrollYProgress,
    [0.84, 0.86, 0.88],
    ['0%', '100%', '0%']
  );
  const k2 = useTransform(scrollYProgress, [0.86, 0.88], ['0%', '100%']);

  const k3 = useTransform(
    scrollYProgress,
    [0.88, 0.9, 0.92],
    ['0%', '100%', '0%']
  );
  const k4 = useTransform(scrollYProgress, [0.9, 0.92], ['0%', '100%']);

  const k5 = useTransform(
    scrollYProgress,
    [0.92, 0.94, 0.96],
    ['0%', '100%', '0%']
  );
  const k6 = useTransform(scrollYProgress, [0.94, 0.96], ['0%', '100%']);

  const k7 = useTransform(
    scrollYProgress,
    [0.96, 0.98, 1],
    ['0%', '100%', '0%']
  );
  const k8 = useTransform(scrollYProgress, [0.98, 1], ['0%', '100%']);

  const k9 = useTransform(scrollYProgress, [0.98, 1], ['0%', '100%']);

  const kickStands = [k1, k2, k3, k4, k5, k6, k7, k8, k9];

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    setIsDocked(latest > 0.35);
  });

  return (
    <LazyMotion features={domAnimation}>
      <section
        id={section.id}
        className={clsx(section.hide && s.hide, s.container)}
        ref={showcaseRef}
      >
        <div className={s.stickyContainer}>
          <m.div className={s.dockHeader} style={{ opacity: headingO2 }}>
            <RichText data={section.modules[4].richText} />
          </m.div>

          <m.div style={{ y: y3, opacity: o3, height: '100%' }}>
            <m.div
              className={s.switchAndDock}
              style={{
                scale: s1,
                x: switchX,
                y: dockY,
                position: 'relative',
                zIndex: 2,
                transformOrigin: 'top',
              }}
            >
              <Switch2Video
                leftJoycon={section.modules[0].assets[0]}
                frameAsset={section.modules[0].assets[1]}
                rightJoycon={section.modules[0].assets[2]}
                video={section.modules[1].assets[0]}
                docked={isDocked}
              />
            </m.div>
            <AnimatePresence>
              {isDocked && (
                <m.div
                  key={'tv-player'}
                  className={s.video}
                  style={{
                    x: tvX,
                    width: '100%',
                    transformOrigin: 'bottom',
                    scale: 0.5,
                  }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0.5, opacity: 0 }}
                >
                  <div className={s.videoContain}>
                    <FramedVideo asset={section.modules[1].assets[1]} stand />
                  </div>
                </m.div>
              )}
            </AnimatePresence>
          </m.div>
        </div>
        <m.div small className={clsx(s.textBlock)} style={{ opacity: opac1 }}>
          <div className={s.scrim}>
            <h2>{section.modules[2]?.body?.content[0]?.content[0]?.value}</h2>
            <div>
              <RichText
                data={{
                  content: section.modules[2]?.body?.content?.slice(1),
                }}
              />
              <Switch2Modal
                page={handheldPage}
                header={section.modules[3].body}
                button={section.modules[3].ctas[0]}
              />
            </div>
          </div>
        </m.div>

        <div className={clsx(s.stickyContainer, s.stickyDock)}>
          <m.div style={{ y: y3, opacity: o3 }}>
            <m.div
              className={s.switchAndDock}
              style={{
                scale: s1,
                x: switchX,
                transformOrigin: 'top',
                opacity: opacDock,
                y: dockY,
              }}
            >
              <div className={s.dock}>
                <Image
                  assetPath={`${section.modules[0].assets[4].primary.assetPath}${isDocked ? '-light' : ''}`}
                  alt={section.modules[0].asset.alt}
                />
              </div>
            </m.div>
          </m.div>
        </div>

        <m.div
          small
          className={clsx(s.textBlock, s.centered)}
          style={{ opacity: opac2 }}
        >
          <div className={clsx(s.scrim, s.centered)}>
            <RichText data={section.modules[5].richText} />
            <Switch2Modal
              page={tvModePage}
              button={section.modules[6]?.ctas[0]}
              header={section.modules[6].body}
            />
            <br />
            <br />
            <div className={s.esrbContain}>
              <Button variant={'ghost'} href={section.modules[7]?.list[0]?.url}>
                {section.modules[7]?.list[0]?.label}
              </Button>
              {section.modules[9] && (
                <ContentRating
                  gamesShown={section.modules[9].gamesShown}
                  rating={section.modules[9].contentRating}
                  descriptors={section.modules[9].contentDescriptors}
                />
              )}
            </div>
          </div>
        </m.div>
        <div className={s.kickStand}>
          {section.modules[8].assets.map((asset, i) => (
            <m.div style={{ opacity: kickStands[i] }} key={asset.alt + i}>
              <div>
                <Image assetPath={asset.primary.assetPath} alt={asset.alt} />
              </div>
            </m.div>
          ))}
        </div>
      </section>
    </LazyMotion>
  );
};

Switch2Showcase.propTypes = {
  section: arrayOf(object),
};

export default Switch2Showcase;
