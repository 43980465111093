import { arrayOf, object } from 'prop-types';
import {
  Button,
  Image,
  Video,
  VideoPlayer,
  PlayButtonIcon,
  AnimationOverlay,
  ShoppingCartIcon,
  Spacer,
} from '@nintendo-of-america/component-library';
import { RichText, Constrained } from '@local/components/';
import {
  FramedVideo,
  Switch2Modal,
} from '@local/components/pages/GamingSystems/Switch2';
import { clsx } from 'clsx';
import s from './Switch2Header.module.css';

const Switch2Header = ({ section }) => {
  return (
    <section
      className={clsx(s.headerContainer, section.hide && s.hide)}
      id={section.deepLink}
    >
      <div className={s.animationContainer}>
        <AnimationOverlay
          animatedImages={section.modules[0].animations}
          inView={true}
          playOnce={true}
          setLoaded={() => true}
          isCurrent={true}
        />
      </div>
      <Constrained small>
        <div className={s.subHeader}>
          <div className={s.release}>
            <div className={s.available}>
              <RichText data={section.modules[1].richText} />
            </div>
            {section.modules[2]?.list[0]?.label && (
              <Button
                icon={ShoppingCartIcon}
                variant="ghost"
                href={section.modules[2].list[0].url}
                buttonWidth={'full'}
              >
                {section.modules[2].list[0].label}
              </Button>
            )}
          </div>
          <Switch2Modal
            className={s.btnLarge}
            variant={'ghost'}
            button={section.modules[2].list[1]}
            btnIcon={PlayButtonIcon}
          >
            <div className={s.youtube}>
              <VideoPlayer
                host="youtube"
                assetPath={section.modules[3].videoId}
              />
            </div>
          </Switch2Modal>
        </div>
        <div className={s.legal}>
          <RichText data={section.modules[4].richText} textVariant="legal" />
        </div>
      </Constrained>
      <Constrained>
        <div className={s.videoContain}>
          <Video
            assetPath={section.modules[5].id}
            controls={false}
            autoplay
            playsInline
            loop
          />
        </div>
      </Constrained>
      <Constrained small>
        <div className={s.whatsNew}>
          <div className={s.whatsNewTitle}>
            <RichText data={section.modules[6].richText} />
          </div>
          <div>
            <RichText data={section.modules[7].richText} />
          </div>
        </div>
        <div className={s.whiteLink}>
          <RichText data={section.modules[8].richText} textVariant={'legal'} />
        </div>

        <div className={s.playerContainer}>
          <FramedVideo asset={section.modules[9]} />
          <div className={s.float}>
            <Image
              assetPath={section.modules[10].primary.assetPath}
              alt={section.modules[10].alt}
            ></Image>
          </div>
        </div>
        <Spacer size={48} />
        <div className={s.desktopOnly}>
          <Spacer size={96} />
        </div>
        <Button
          variant={'ghost'}
          size={'large'}
          href={section.modules[11].list[0].url}
        >
          {section.modules[11].list[0].label}
        </Button>
      </Constrained>
    </section>
  );
};

Switch2Header.propTypes = {
  section: arrayOf(object),
};

export default Switch2Header;
