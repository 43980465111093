import {
  Image,
  PlusIcon,
  MinusIcon,
} from '@nintendo-of-america/component-library';
import { ContentRating, RichText, CloudinaryGallery } from '@local/components';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import * as S from './RetailOffer.styles';
import { useState } from 'react';

function RetailOffer({ section }) {
  const [isExpanded, setIsExpanded] = useState(
    section.modifiers.includes('reverseContent')
  );
  const scrimDark = section.modifiers.includes('scrimDark');
  const { text } = useLocalizer();
  const gradientColors =
    section.background?.color?.replace(')', '').split(',') || [];
  const dividerColor = gradientColors[gradientColors?.length - 1];

  return (
    <>
      <S.OfferContainer $scrimDark={scrimDark}>
        <RichText data={section?.modules[0].richText} />
        <S.OfferGrid>
          <div>
            {section?.modules[1]?.assets.length === 1 ? (
              <S.SingleItem
                assetPath={section?.modules[1]?.assets[0]?.primary?.assetPath}
                alt={section?.modules[1]?.assets[0]?.alt}
              />
            ) : (
              <CloudinaryGallery border assets={section?.modules[1]?.assets} />
            )}
          </div>
          <div>
            <S.ProductInfo>
              <RichText data={section.modules[2].richText} />
            </S.ProductInfo>
            <RichText data={section.modules[3]?.body} />
            {section.modules[3]?.ctas && (
              <S.RetailOffers>
                {section.modules[3].ctas.map(
                  (retailer, i) =>
                    section.modules[3]?.assets[i] &&
                    retailer && (
                      <a
                        href={retailer.url}
                        key={retailer.label + i}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <S.Retailer>
                          <Image
                            assetPath={
                              section.modules[3]?.assets[i]?.primary?.assetPath
                            }
                            alt={
                              section.modules[3]?.assets[i]?.alt ||
                              retailer?.label
                            }
                          />
                        </S.Retailer>
                      </a>
                    )
                )}
              </S.RetailOffers>
            )}
            {section.modules[4]?.richText && (
              <>
                <S.ReadMore expanded={isExpanded}>
                  <RichText data={section.modules[4].richText} />
                </S.ReadMore>
                <S.StyledIconLink
                  icon={isExpanded ? MinusIcon : PlusIcon}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? text('Less info') : text('More info')}
                </S.StyledIconLink>
              </>
            )}
          </div>
          {section.modules[5]?.contentRating ? (
            <ContentRating
              gamesShown={section.modules[5]?.gamesShown}
              rating={section.modules[5]?.contentRating}
              descriptors={section.modules[5]?.contentDescriptors}
            />
          ) : (
            <div>
              <RichText
                textVariant="legal"
                data={section.modules[5]?.richText}
              />
            </div>
          )}
          {section.modules[6]?.contentRating ? (
            <ContentRating
              gamesShown={section.modules[6]?.gamesShown}
              rating={section.modules[6]?.contentRating}
              descriptors={section.modules[6]?.contentDescriptors}
            />
          ) : (
            <div>
              <RichText
                textVariant="legal"
                data={section.modules[6]?.richText}
              />
            </div>
          )}
        </S.OfferGrid>
      </S.OfferContainer>
      {section.background && (
        <S.Divider $bg={section.background} $dividerColor={dividerColor} />
      )}
    </>
  );
}

RetailOffer.bluePrint = [
  { ['richText*']: 'used for Header' },
  { ['listAssets*']: 'Media Gallery' },
  {
    ['richText*']:
      'Product Information, Emphasis from inline text entry styled emphasis',
  },
  { ['peonPromo*']: 'Assets and ctas for retailer links' },
  { richText: 'Read more copy' },
  { moduleContentRating: '' },
  { richText: 'Legal copy' },
];

export default RetailOffer;
