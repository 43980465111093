import { useRef } from 'react';
import { arrayOf, object } from 'prop-types';
import { RichText } from '@local/components';
import { Image, Video } from '@nintendo-of-america/component-library';
import { useScroll, motion, useTransform } from 'framer-motion';
import { Switch2Modal } from '@local/components/pages/GamingSystems/Switch2';
import clsx from 'clsx';

import s from './Switch2Joycon.module.css';

// PROOF OF CONCEPT
// possible framer motion parallax method
const Switch2Joycon = ({ section, modalPage1, modalPage2 }) => {
  const joyconRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: joyconRef });
  const y1 = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ['100%', '100%', '0%', '0%', '0%']
  );
  const slide2X = useTransform(scrollYProgress, [0.35, 0.6], ['-100%', '-50%']);
  const slide2Y = useTransform(scrollYProgress, [0.35, 0.65], ['60vh', '0vh']);
  const slide3Y = useTransform(scrollYProgress, [0.9, 1], ['30%', '0%']);
  const x2 = useTransform(
    scrollYProgress,
    [0, 0.25, 0.65, 0.9, 1],
    ['-100%', '-100%', '-100%', '0%', '0%']
  );
  const slide3X = useTransform(
    scrollYProgress,
    [0, 0.8, 1],
    ['-10%', '-5%', '5%']
  );
  const slide3O = useTransform(
    scrollYProgress,
    [0, 0.8, 0.9],
    ['0%', '5%', '100%']
  );
  return (
    <section className={s.container} ref={joyconRef} id={section.deepLink}>
      <motion.div
        className={s.slide}
        style={{
          x: 0,
          y: 0,
          position: 'sticky',
          overflow: 'hidden',
          top: '1rem',
        }}
      >
        {!section.modules[0].asset.secondary?.assetPath ? (
          <div className={s.logo}>
            <Image
              assetPath={section.modules[0].asset?.primary?.assetPath}
              alt={section.modules[0]?.asset?.alt}
            />
          </div>
        ) : (
          <div className={s.motionVideo}>
            <Video
              autoplay={true}
              playsInline
              controls={false}
              assetPath={section.modules[0]?.asset?.secondary?.assetPath}
            />
          </div>
        )}
        <motion.div
          className={clsx(s.slide, s.two)}
          style={{
            x: 0,
            y: y1,
          }}
        >
          <motion.div className={s.switchAngled} style={{ x: slide2X }}>
            <Image
              assetPath={section.modules[1].asset.primary.assetPath}
              alt={section.modules[1].asset.alt}
            />
          </motion.div>
          <motion.div className={s.textBox} style={{ y: slide2Y }}>
            <h2>
              {section.modules[1].body?.content[0]?.content[0]?.value}
              <em>{section.modules[1].body?.content[0]?.content[1]?.value}</em>
            </h2>
            <div className={s.video}>
              <Video
                assetPath={section.modules[1]?.assets[1]?.primary?.assetPath}
                controls={false}
                autoplay
                playsInline
                loop
              />
            </div>
            <RichText
              data={{ content: section.modules[1]?.body?.content?.slice(2) }}
            />

            <Switch2Modal
              page={modalPage1}
              header={section.modules[2].body}
              button={section.modules[2].ctas[0]}
              variant={'ghost'}
            />
          </motion.div>
        </motion.div>

        <motion.div
          className={clsx(s.slide, s.three)}
          style={{
            x: x2,
          }}
        >
          <motion.div className={s.joyconHand} style={{ x: slide3X }}>
            <Image
              assetPath={section.modules[3].asset.primary.assetPath}
              alt={section.modules[3].asset.alt}
            />
          </motion.div>
          <motion.div
            className={s.textBox}
            style={{ y: slide3Y, opacity: slide3O }}
          >
            <div className={s.slide3Grid}>
              <div>
                <h2>
                  {section.modules[3].body?.content[0]?.content[0]?.value}
                </h2>
                <div className={s.video}>
                  <Video
                    assetPath={
                      section.modules[3]?.assets[1]?.primary?.assetPath
                    }
                    controls={false}
                    autoplay
                    playsInline
                    loop
                  />
                </div>
                <RichText
                  data={{ content: section.modules[3].body?.content?.slice(1) }}
                />
                <Switch2Modal
                  page={modalPage2}
                  header={section.modules[4].body}
                  button={section.modules[4].ctas[0]}
                  variant={'ghost'}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

Switch2Joycon.propTypes = {
  section: arrayOf(object),
};

export default Switch2Joycon;
