import { object } from 'prop-types';
import { PeonWrapper } from './peon-components';
import * as PeonComponents from './peon-components';
import { PREVIEW_MODE } from '@shared/util/constants';

function Peon({ marketingPage, productProps }) {
  const { modules } = marketingPage;

  return (
    <>
      {modules.map((section, i) => {
        const Section = PeonComponents[section.sectionType || 'MultiContent'];
        const bluePrint = section.sectionType
          ? Section.bluePrint
          : [{ selectSectionType: 'Or add background' }];

        return (
          <PeonWrapper
            section={section}
            preview={PREVIEW_MODE}
            bluePrint={bluePrint}
            fullWidth={Section.fullWidth}
            key={section.sectionType + i}
          >
            <Section
              section={section}
              preview={PREVIEW_MODE}
              productProps={productProps}
            />
          </PeonWrapper>
        );
      })}
    </>
  );
}

Peon.propTypes = {
  page: object,
};

export default Peon;
