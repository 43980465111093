import { Children, cloneElement } from 'react';

/**
 * Proxy for framework specific Link components
 * When an app does not provide a ProxyLink component in the theme, this proxy
 * component is used instead to render its children and pass along the href
 */
export default function ProxyLink({ href, children }) {
  const child = cloneElement(Children.only(children), { href });
  return child;
}
