import { arrayOf, object } from 'prop-types';
import { Heading, Image } from '@nintendo-of-america/component-library';
import { AccordionRow, Constrained, RichText } from '@local/components';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import clsx from 'clsx';
import s from './Switch2Modes.module.css';

const Switch2Modes = ({ section }) => {
  const miniBlade = section?.modules[section.modules.length - 1] || null;

  return (
    <LazyMotion features={domAnimation}>
      <section
        className={clsx(s.modesContainer, section.hide && s.hide)}
        id={section.deepLink}
      >
        <m.div
          animate={{ x: '-100%' }}
          transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
        >
          <div className={s.imgSlider}>
            {section.modules[0].assets.map((asset) => (
              <Image
                key={asset.primary.assetPath}
                assetPath={asset.primary.assetPath}
                alt={asset.alt}
              />
            ))}
            <div className={s.duplicates}>
              {section.modules[0].assets.map((asset) => (
                <Image
                  key={asset.primary.assetPath + 'dup'}
                  assetPath={asset.primary.assetPath}
                  alt={asset.alt}
                />
              ))}
            </div>
          </div>
        </m.div>
        <Constrained className={s.grid} small>
          <div className={s.modes}>
            <div className={s.modesTitle}>
              <h3>
                {section.modules[1].richText?.content[0]?.content[0]?.value}
              </h3>
            </div>
            <div>
              <RichText
                data={{
                  content: section.modules[1].richText?.content?.slice(1),
                }}
              />
            </div>
          </div>
          <div className={s.imgGrid}>
            {section.modules[2].assets.map((mode) => (
              <div className={s.mode} key={'mode' + mode.caption}>
                <Image
                  assetPath={mode.primary.assetPath}
                  alt={mode.alt}
                ></Image>
                {mode.caption}
              </div>
            ))}
          </div>
          <hr className={s.modeHr} />
          <div>
            {section.modules
              .slice(3, section.modules.length - 1)
              .map((module, i) =>
                module.__contentType === 'peonPromo' ? (
                  <div className={s.accHeading} key={module.asset.alt}>
                    <Image
                      assetPath={module.asset.primary.assetPath}
                      alt={module.asset.alt}
                    ></Image>
                    <Heading variant="h2">
                      {module.body?.content[0]?.content[0]?.value}
                    </Heading>
                  </div>
                ) : (
                  <div key={'faqSection' + i}>
                    {module.list.map((faq, i) => (
                      <div key={i + 'faqlist1'}>
                        <AccordionRow
                          rounded
                          heading={faq.body?.content[0]?.content[0]?.value}
                          redVariant={true}
                        >
                          <div className={s.innerContent}>
                            <RichText
                              data={{ content: faq.body?.content?.slice(1) }}
                            />
                          </div>
                        </AccordionRow>
                      </div>
                    ))}
                    <hr className={s.modeHr} />
                  </div>
                )
              )}
          </div>
          <div className={s.miniBlade}>
            <div className={s.miniContent}>
              <div>
                <h3>{miniBlade?.body?.content[0]?.content[0]?.value}</h3>
              </div>
              <RichText
                data={{ content: miniBlade?.body?.content?.slice(1) }}
              />
            </div>
            <div>
              <Image
                className={s.miniImg}
                assetPath={miniBlade.asset.primary.assetPath}
                alt={miniBlade.asset.alt}
              />
              <div className={s.legal}>{miniBlade.asset.alt}</div>
            </div>
          </div>
        </Constrained>
      </section>
    </LazyMotion>
  );
};

Switch2Modes.propTypes = {
  section: arrayOf(object),
};

export default Switch2Modes;
