import defaultTheme from './default';
import { CATEGORY } from './constants';

export default {
  ...defaultTheme,
  name: 'eShop',
  slug: 'eshop',
  category: CATEGORY.GENERAL,
  description: 'This is the theme used in the eshop',
  color: {
    ...defaultTheme.color,
    primary: '#ff7d00',
    primaryHover: '#f26c14',
  },
};
