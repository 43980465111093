import { Image, VideoPlayer } from '@nintendo-of-america/component-library';
import { LazyMotion, domAnimation } from 'framer-motion';
import { RichText } from '@local/components';
import { responsiveAssetPath } from '@local/lib/helpers';
import PeonBase from '../PeonBase';
import * as S from './HeaderSlim.styles';

function HeaderSlim({ section }) {
  const reverseContent = section.modifiers.includes('reverseContent');

  return (
    <PeonBase
      section={section}
      peonPromo={(module) => (
        <LazyMotion features={domAnimation} key={section.__entryId}>
          <S.Header>
            <S.AnimateLogo
              $disable={section.modifiers.includes('animationDisable')}
              $reverseContent={reverseContent}
              $constrain={module.asset?.primary?.assetPath}
            >
              {module.asset?.primary?.assetPath ? (
                <>
                  {module.asset?.primary.resourceType === 'image' ? (
                    <Image
                      assetPath={responsiveAssetPath(module.asset)}
                      alt={module?.asset?.alt}
                    />
                  ) : (
                    <S.VideoContainer>
                      <VideoPlayer
                        assetPath={module.asset?.primary?.assetPath}
                      />
                    </S.VideoContainer>
                  )}
                  {module.body && (
                    <div>
                      <RichText data={module.body} />
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <RichText data={module.body} />
                </div>
              )}
            </S.AnimateLogo>
          </S.Header>
        </LazyMotion>
      )}
    />
  );
}

HeaderSlim.bluePrint = [{ peonPromo: '' }];
HeaderSlim.fullWidth = true;

export default HeaderSlim;
