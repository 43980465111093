import { arrayOf, object } from 'prop-types';
import { Constrained, RichText } from '@local/components';
import {
  FramedVideo,
  NSOCard,
  Switch2Modal,
} from '@local/components/pages/GamingSystems/Switch2';
import {
  Button,
  Image,
  AnimationOverlay,
} from '@nintendo-of-america/component-library';
import clsx from 'clsx';
import s from './Switch2Social.module.css';

const Switch2Social = ({ section, nsoCard, modalPage }) => {
  return (
    <section
      className={clsx(s.socialSection, section.hide && s.hide)}
      id={section.deepLink}
    >
      <RichText data={section.modules[0].richText} />
      <div className={s.animationContainer}>
        <AnimationOverlay
          animatedImages={section.modules[1].animations}
          inView={true}
          playOnce={section.modifiers.includes('animationDisable')}
          setLoaded={() => true}
          isCurrent={true}
        />
      </div>
      <div className={s.container}>
        <Constrained>
          <div className={s.media}>
            <Image
              assetPath={section.modules[2].primary.assetPath}
              alt={section.modules[2].alt}
            />
            <div className={s.videoContain}>
              <FramedVideo asset={section.modules[3]} stand></FramedVideo>
            </div>
          </div>
        </Constrained>
        <Constrained>
          <div className={s.content}>
            <div>
              <RichText data={section.modules[4].richText} />
              <Switch2Modal
                button={section.modules[5].ctas[0]}
                header={section.modules[5].body}
                page={modalPage}
              />
            </div>
            <div className={s.accessory}>
              <div className={s.socialImg}>
                <Image
                  assetPath={section.modules[6].asset.primary.assetPath}
                  alt={section.modules[6].asset.alt}
                />
              </div>
              <div>
                <RichText data={section.modules[6].body} />
              </div>
              <Button href={section.modules[6].cta.url} variant={'secondary'}>
                {section.modules[6].cta.label}
              </Button>
            </div>
          </div>
        </Constrained>
      </div>
      <div className={s.nsoContain}>
        <NSOCard module={nsoCard} />
      </div>
    </section>
  );
};

Switch2Social.propTypes = {
  section: arrayOf(object),
};

export default Switch2Social;
