import { arrayOf, object } from 'prop-types';
import { Image } from '@nintendo-of-america/component-library';
import { Constrained, RichText, ContentRating } from '@local/components';
import clsx from 'clsx';
import s from './Switch2Gen.module.css';

const Switch2Gen = ({ section }) => {
  return (
    <section
      className={clsx(s.generations, section.hide && s.hide)}
      style={{ backgroundImage: section.background.backgroundType }}
      id={section.deepLink}
    >
      <Constrained className={s.grid} small>
        <div className={s.shadow}>
          <RichText data={section.modules[0].richText} />
        </div>
        {section.modules[2] && (
          <div className={s.esrb}>
            <ContentRating
              gamesShown={section.modules[2].gamesShown}
              rating={section.modules[2].contentRating}
              descriptors={section.modules[2].contentDescriptors}
            />
          </div>
        )}
      </Constrained>
      <div className={s.assets}>
        <Image
          assetPath={section.modules[1].assets[0].primary.assetPath}
          alt={section.modules[1].assets[0].alt}
        ></Image>
        <Image
          assetPath={section.modules[1].assets[1].primary.assetPath}
          alt={section.modules[1].assets[1].alt}
        ></Image>
      </div>
    </section>
  );
};

Switch2Gen.propTypes = {
  section: arrayOf(object),
};

export default Switch2Gen;
