import styled, { css } from 'styled-components';
import { LinkItem } from '@local/components';
import { ContentRating } from '@local/components';

export const CustomHeadingCta = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: ${({ $textCenter }) =>
    $textCenter ? 'center' : 'flex-start'};

  p {
    margin: 0;
  }

  a > span,
  a:hover span {
    color: ${({ theme, $color }) => theme.color[$color]};
    padding: 0.25rem 0;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border 0.3s ease-in-out;

    &:hover {
      border-bottom: 1px solid ${({ theme, $color }) => theme.color[$color]};
    }
  }
  ${({ theme }) => theme.mediaQuery.tablet} {
    justify-content: flex-start;
  }
`;

export const HeadingTitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.h2Desktop};
  color: ${({ theme, $color }) => theme.color[$color]};
  text-align: center;
  margin: 0;
  padding-right: ${({ $hasLink }) => $hasLink && '1rem'};
  border-right: ${({ $hasLink }) => $hasLink && '1px solid currentcolor'};
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    flex-basis: ${({ $items }) => `${Math.max(100 / $items, 50)}%`};
    padding: 0.5rem;
    ${({ theme }) => theme.mediaQuery.tablet} {
      flex-basis: ${({ $items }) => `${Math.max(100 / $items, 25)}%`};
    }
  }
`;

export const StyledContentRating = styled(ContentRating)`
  justify-self: center;
`;

export const RichTextContainer = styled.div`
  ${({ $scrimLight, $scrimDark }) =>
    ($scrimLight || $scrimDark) &&
    css`
      justify-self: center;
      width: fit-content;
      padding: 1rem;
      border-radius: ${({ theme }) => theme.borderRadius};
      color: ${({ theme, $scrimDark }) =>
        $scrimDark ? theme.color.white : theme.color.darkGray1};
      background: ${({ theme, $scrimDark }) =>
        $scrimDark ? theme.scrim.dark : theme.scrim.light};
    `}
`;

export const AnimationContainer = styled.div`
  height: ${({ $fullWidth }) => ($fullWidth ? '108vw' : '97vw')};
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaQuery.desktop} {
    height: ${({ $fullWidth }) => ($fullWidth ? '31.25vw' : '27.25vw')};
    max-height: ${({ $fullWidth }) => ($fullWidth ? 'unset' : '345px')};
  }
`;

export const AnimationHeader = styled.h2`
  position: absolute;
`;

export const StyledLinkItem = styled(LinkItem)`
  border-radius: 0;
`;
