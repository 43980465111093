import { arrayOf, object } from 'prop-types';
import { Button, Image } from '@nintendo-of-america/component-library';
import { ContentRating } from '@shared/ui';
import { Constrained, LinkItem } from '@local/components';
import clsx from 'clsx';
import s from './Switch2Featured.module.css';

const Switch2Featured = ({ section }) => {
  return (
    <section
      className={clsx(s.container, section.hide && s.hide)}
      id={section.id}
    >
      <Constrained className={s.grid} small>
        <h2>Switch2Featured</h2>

        <div className={s.promoBlade}>
          <LinkItem href={'/test'} ariaLabel={'test'}>
            <Image assetPath={'/dev-test/placeholder'} alt="test" />
          </LinkItem>

          <div className={clsx(s.caption, s.scrimDark)}>
            <div className={s.titleAndCta}>
              <h3>test</h3>
              <Button variant={'ghost'}>test</Button>
            </div>
            <ContentRating
              className={s.lightRating}
              gamesShown={true}
              rating={{
                id: '3PUsVpTIBTxVxmzL362ZkB',
                locale: 'en_US',
                code: 'e',
                label: 'Everyone',
                requiresAgeGate: false,
                system: 'ESRB',
              }}
              // gamesShown={rating.gamesShown}
              // rating={rating.contentRating}
              // descriptors={rating.contentDescriptors}
            />
          </div>
        </div>
      </Constrained>
      <Constrained small>
        <h2>Developer lorem</h2>
        <p>lorem ippsum 3 images</p>
      </Constrained>
    </section>
  );
};

Switch2Featured.propTypes = {
  section: arrayOf(object),
};

export default Switch2Featured;
