import { LazyMotion, domAnimation, m } from 'framer-motion';
import s from './OverlinedTitle.module.css';
import { Spacer } from '@nintendo-of-america/component-library';
import { useReducedMotion } from '@shared/util';

/**
 * Overlined Title
 *
 * @param {String} text - text to display in title
 * @param {boolean} gray - gray styling variant
 *
 */

const OverlinedTitle = ({ text, gray }) => {
  const prefersReducedMotion = useReducedMotion();
  const animationProps = prefersReducedMotion
    ? {}
    : {
        initial: { scale: 0, opacity: 0.5 },
        whileInView: {
          scale: 1,
          opacity: 1,
          transition: {
            type: 'spring',
            duration: 0.3,
            stiffness: 200,
            bounce: 0.1,
            delay: 0.2,
          },
        },
      };
  return (
    <LazyMotion features={domAnimation}>
      <div className={s.titleContainer}>
        <m.div className={s.overlined} {...animationProps} />
        <Spacer size={32} />
        <div className={gray ? s.gray : s.red}>{text}</div>
      </div>
    </LazyMotion>
  );
};

export default OverlinedTitle;
