import defaultTheme from './default';
import { CATEGORY } from './constants';

export default {
  ...defaultTheme,
  name: 'Tertiary',
  slug: 'tertiary',
  category: CATEGORY.IP,
  description: 'Sets tertiary as the primary theme color',
  color: {
    ...defaultTheme.color,
    primary: defaultTheme.color.secondary,
    primaryHover: defaultTheme.color.secondaryHover,
  },
};
