import { useState } from 'react';
import { object, string } from 'prop-types';
import { RichText } from '@local/components';
import { Modal, Button } from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import s from './Switch2Modal.module.css';
import Peon from '@local/components/pages/templates/peon/peon';
const Switch2Modal = ({
  header,
  variant,
  children,
  button,
  page,
  className,
  btnIcon,
}) => {
  const { text } = useLocalizer();
  const [modal, toggleModal] = useState(false);

  const transition = () => {
    toggleModal((modal) => !modal);
  };

  return (
    <>
      <Button
        onClick={transition}
        variant={variant}
        className={className}
        icon={btnIcon}
      >
        {button.label}
      </Button>
      <Modal dialogLabel={text('Close')} visible={modal} onClose={transition}>
        {header && (
          <div className={s.titleContain}>
            <div className={s.subHead}>
              {header?.content[0]?.content[0]?.value}
            </div>
            <RichText
              data={{
                content: header?.content?.slice(1),
              }}
            />
          </div>
        )}
        {children && modal && <div className={s.childContain}>{children}</div>}
        {page && <Peon marketingPage={page.content} />}
      </Modal>
    </>
  );
};

Switch2Modal.propTypes = {
  header: object, //richText
  variant: string, //btn variant
  page: object, //Modular page (peon)
  button: object, //contentful: cta
};

export default Switch2Modal;
