import s from './HeroSection.module.css';
import RichText from '@local/components/RichText';
import { Heading, Spacer } from '@nintendo-of-america/component-library';

const HeroSection = ({ modules }) => {
  return (
    <div className={s.textContainer}>
      <Heading large={true} variant="h1">
        {modules?.[0]?.heading}
      </Heading>
      <Spacer size={36} />
      <RichText
        className={s.textAlign}
        data={modules?.[0]?.modules?.[1]?.richText}
      />
      <RichText
        data={modules?.[0]?.modules?.[2]?.richText}
        textVariant="legal"
      />
    </div>
  );
};

export default HeroSection;
