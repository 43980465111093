import { arrayOf, object } from 'prop-types';
import { Image, Video } from '@nintendo-of-america/component-library';
import { MODULE_TYPES } from '@nintendo-of-america/contentful-api/constants';

import { RichText } from '@local/components';
import Section from '@local/components/Section';
import s from './Switch2Together.module.css';

const Switch2Together = ({ section }) => {
  return (
    <Section
      className={s.together}
      constrained
      id={section.deepLink}
      hide={section.hide}
    >
      <div className={s.imgGrid}>
        <div className={s.left1}>
          <Image
            className={s.gridItem}
            assetPath={section.modules[0].assets[0].primary.assetPath}
            alt={section.modules[0].assets[0].alt}
          />
        </div>
        <div className={s.left2}>
          <Image
            className={s.gridItem}
            assetPath={section.modules[0].assets[1].primary.assetPath}
            alt={section.modules[0].assets[1].alt}
          />
        </div>
        <div className={s.centerVid}>
          {section.modules[0].assets[2].__contentType ===
          MODULE_TYPES.CLOUDINARY_IMAGE ? (
            <Image
              assetPath={section.modules[0]?.assets[2]?.primary?.assetPath}
              alt={section?.modules[0]?.assets[2]?.alt}
            />
          ) : (
            <Video
              assetPath={section?.modules[0]?.assets[2]?.id}
              playsInline
              autoplay
              controls={false}
              loop
            />
          )}
        </div>
        <div className={s.right1}>
          <Image
            className={s.gridItem}
            assetPath={section.modules[0].assets[3].primary.assetPath}
            alt={section.modules[0].assets[3].alt}
          />
        </div>
        <div className={s.right2}>
          <Image
            className={s.gridItem}
            assetPath={section.modules[0].assets[4].primary.assetPath}
            alt={section.modules[0].assets[4].alt}
          />
        </div>
      </div>

      <div className={s.grid2}>
        <div className={s.textItem}>
          <RichText data={section.modules[1].body} />
        </div>
        <div>
          <Image
            className={s.gridItem}
            assetPath={section.modules[1].asset.primary.assetPath}
            alt={section.modules[1].asset.alt}
          />
        </div>
      </div>

      <div className={s.grid3}>
        <div className={s.leftHalf}>
          <Image
            className={s.gridItem}
            assetPath={section.modules[2].assets[0].primary.assetPath}
            alt={section.modules[2].assets[0].alt}
          />
        </div>
        {section.modules[2].assets
          .slice(1, section.modules[2].assets.length - 1)
          .map((asset) => (
            <Image
              className={s.gridItem}
              assetPath={asset.primary.assetPath}
              alt={asset.alt}
              key={asset.alt}
            />
          ))}
        <div className={s.rightHalf}>
          <Image
            className={s.gridItem}
            assetPath={
              section.modules[2].assets[section.modules[2].assets.length - 1]
                .primary.assetPath
            }
            alt={
              section.modules[2].assets[section.modules[2].assets.length - 1]
                .alt
            }
          />
        </div>
      </div>
    </Section>
  );
};

Switch2Together.propTypes = {
  section: arrayOf(object),
};

export default Switch2Together;
