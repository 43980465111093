import { Image } from '@nintendo-of-america/component-library';
import s from './Triptych.module.css';

const Triptych = ({ module }) => {
  const modules = module?.modules;
  return (
    <div className={s.grid}>
      <div className={s.verticalgrid}>
        <Image
          assetPath={modules?.[0]?.primary?.assetPath}
          alt={modules?.[0]?.alt}
        />
        <Image
          assetPath={modules?.[1]?.primary?.assetPath}
          alt={modules?.[1]?.alt}
        />
      </div>
      <Image
        assetPath={modules?.[2]?.primary?.assetPath}
        alt={modules?.[2]?.alt}
      />
    </div>
  );
};

export default Triptych;
