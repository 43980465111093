import { AnyUp, RichText } from '@local/components';
import { StoreProductTile } from '@shared/ui';
import {
  Rail,
  Spacer,
  Image,
  VideoPlayer,
  Button,
  AnimationOverlay,
} from '@nintendo-of-america/component-library';
import { Link } from '@nintendo-of-america/next';
import { responsiveAssetPath } from '@local/lib/helpers';
import {
  LIST_TYPES,
  MODULE_TYPES,
  COMPONENT_TYPES,
} from '@nintendo-of-america/contentful-api/constants';
import * as S from './PeonBase.styles';

function PeonBase({
  section,
  productProps,
  contentRating,
  peonPromo,
  richText,
  listAssets,
  listPeonPromos,
  listProducts,
  listCtas,
  carouselSlide,
  cloudinaryAsset,
  youtubeVideo,
  children,
}) {
  const scrimDark = section.modifiers?.includes('scrimDark');
  const scrimLight = section.modifiers?.includes('scrimLight');
  if (section.modules?.length === 0 && section?.background) {
    return <Spacer size={256} />;
  }
  return section.modules.map((module) => {
    const contentType = module.__contentType || module.CONTENT_TYPE;
    switch (contentType) {
      case MODULE_TYPES.PEON_PROMO:
        return (
          (peonPromo && peonPromo(module)) || (
            <S.CustomHeadingCta
              $color={
                section?.background?.textColor === 'Light'
                  ? 'white'
                  : 'darkGray1'
              }
              $ctaUrl={module.cta?.url}
              $textCenter={section.modifiers.includes('textCenter')}
            >
              <S.HeadingTitle $hasLink={module.cta}>
                <RichText data={module.body} headingMargin={false} />
              </S.HeadingTitle>
              {module.cta && (
                <Link href={module.cta?.url}>{module.cta?.label}</Link>
              )}
            </S.CustomHeadingCta>
          )
        );
      case LIST_TYPES.PEON_PROMOS:
        return (
          (listPeonPromos && listPeonPromos(module)) || (
            <>
              <AnyUp
                reverseContent={section.modifiers.includes('reverseContent')}
                noScrim={
                  !section.modifiers.includes('scrimLight') &&
                  !section.modifiers.includes('scrimDark')
                }
                columnsMobile={section.gridMobile}
                lightScrim={section.modifiers.includes('scrimLight')}
                modules={module.list}
                productProps={productProps}
              />
            </>
          )
        );
      case COMPONENT_TYPES.CAROUSEL_SLIDE: {
        return (
          (carouselSlide && carouselSlide(module)) || (
            <S.StyledLinkItem href={module.cta?.url} ariaLabel={module.heading}>
              <S.AnimationContainer
                $fullWidth={section.sectionType?.includes('HeaderAnimated')}
              >
                {module.heading && (
                  <S.AnimationHeader aria-label={module.heading} />
                )}
                <AnimationOverlay
                  animatedImages={module.animations}
                  inView={true}
                  playOnce={section.modifiers.includes('animationDisable')}
                  setLoaded={() => true}
                  isCurrent={true}
                />
              </S.AnimationContainer>
            </S.StyledLinkItem>
          )
        );
      }
      case LIST_TYPES.PRODUCTS:
        return (
          (listProducts && listProducts(module)) || (
            <Rail centered contained>
              {module.products.map((product) => (
                <StoreProductTile key={product.sku} {...product} />
              ))}
            </Rail>
          )
        );
      case LIST_TYPES.ASSETS:
        return (
          (listAssets && listAssets(module)) || (
            <S.Grid $items={module.assets.length}>
              {module.assets.map((asset) =>
                asset.__contentType === MODULE_TYPES.CLOUDINARY_IMAGE ? (
                  <Image
                    key={asset.path}
                    assetPath={responsiveAssetPath(asset)}
                    alt={asset.alt}
                  />
                ) : (
                  <div key={asset.id}>
                    <VideoPlayer assetPath={asset.id} />
                  </div>
                )
              )}
            </S.Grid>
          )
        );
      case MODULE_TYPES.CLOUDINARY_IMAGE:
        return (
          (cloudinaryAsset && cloudinaryAsset(module)) || (
            <Image assetPath={responsiveAssetPath(module)} alt={module.alt} />
          )
        );
      case MODULE_TYPES.CLOUDINARY_VIDEO:
        return (
          (cloudinaryAsset && cloudinaryAsset(module)) || (
            <VideoPlayer assetPath={module.id} />
          )
        );
      case MODULE_TYPES.YOUTUBE_VIDEO:
        return (
          (youtubeVideo && youtubeVideo(module)) || (
            <VideoPlayer
              noCookie={module.nocookie}
              host="youtube"
              timeStamp={module.videoId.split('?t=' || [])[1]}
              assetPath={module.videoId}
            />
          )
        );
      case LIST_TYPES.MODULE_CTAS:
        return (
          (listCtas && listCtas(module)) || (
            <S.Flex>
              {module.list.map((cta) => (
                <Button href={cta.url} key={cta.url}>
                  {cta.label}
                </Button>
              ))}
            </S.Flex>
          )
        );
      case MODULE_TYPES.RICH_TEXT:
        return (
          (richText && richText(module)) || (
            <S.RichTextContainer
              $scrimLight={scrimLight}
              $scrimDark={scrimDark}
            >
              <RichText
                data={module.richText}
                headingMargin={false}
                productProps={productProps}
              />
            </S.RichTextContainer>
          )
        );
      case MODULE_TYPES.CONTENT_RATING:
        return (
          (contentRating && contentRating(module)) ||
          (module.contentRating && (
            <S.StyledContentRating
              gamesShown={module.gamesShown}
              textColor={section.background?.textColor?.toLowerCase()}
              rating={module.contentRating}
              descriptors={module.contentDescriptors}
            />
          ))
        );
    }
    return children;
  });
}

export default PeonBase;
