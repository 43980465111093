import { LeftBorderTitle } from '@local/components/pages/GamingSystems/Switch2/';
import { Image, Spacer } from '@nintendo-of-america/component-library';
import s from './ImageDescriptionGrid.module.css';
import RichText from '@local/components/RichText';

const ImageDescriptionItem = ({ item }) => {
  return (
    <div className={s.itemContainer}>
      <Image
        assetPath={item?.asset?.primary?.assetPath}
        alt={item?.asset?.primary?.alt}
      />
      <Spacer size={24} />
      <LeftBorderTitle
        heading={item?.asset?.caption}
        subheading={item?.heading}
      />
      <RichText className={s.richText} data={item?.body} />
    </div>
  );
};

export default ImageDescriptionItem;
