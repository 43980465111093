import ShareLinkCard from './ShareLinkCard';
import s from './ShareLinkSection.module.css';

const ShareLinkSection = ({ module }) => {
  const modules = module?.modules;
  return (
    <div className={s.background}>
      {modules?.map((item, i) => (
        <div key={i}>
          <ShareLinkCard item={item} />
          {i !== modules?.length - 1 && <hr className={s.divider} />}
        </div>
      ))}
    </div>
  );
};

export default ShareLinkSection;
