import s from './PreviewCard.module.css';
import RichText from '@local/components/RichText';
import {
  Button,
  Spacer,
  Image,
  ChevronRightIcon,
} from '@nintendo-of-america/component-library';
import { OverlinedTitle } from '@local/components/pages/GamingSystems/Switch2/';
import clsx from 'clsx';

/**
 * Preview Card
 *
 * @param {Object} module - Module: Section object containing an array of one Promo C object
 * @example
 * // example argument
 * const module = {
 *  modules: [
 *      {Promo C object},
 *  ]
 * }
 */

const PreviewCard = ({ module, centeredCta = false, className }) => {
  const modules = module;
  const assetPath = modules?.modules?.[0]?.asset?.primary?.assetPath;
  return (
    <div className={clsx(s.card, className && className)}>
      <OverlinedTitle text={modules?.heading} />
      <Spacer size={32} />
      <h2 className={s.header}>{modules?.modules?.[0]?.heading}</h2>
      <div className={clsx(s.innergrid, !assetPath && s.singleColumn)}>
        {assetPath && (
          <Image
            assetPath={assetPath}
            alt={modules?.modules?.[0]?.asset?.alt}
          />
        )}
        <div className={clsx(centeredCta && s.centeredCta)}>
          <RichText className={s.detail} data={modules?.modules?.[0]?.body} />
          {modules?.modules?.[0]?.cta?.label && (
            <Button
              className={clsx(s.detail, s.button)}
              href={modules?.modules?.[0]?.cta?.url}
            >
              {modules?.modules?.[0]?.cta?.label}
              <ChevronRightIcon size={18} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
