import { SectionCarousel } from '@local/components/pages/PMP';
import { defaultTheme as theme } from '@nintendo-of-america/component-library';
import PeonBase from '../PeonBase';
import * as S from './CharacterCarousel.styles';

function CharacterCarousel({ section }) {
  const scrim = {
    light: section?.modifiers?.includes('scrimLight'),
    dark: section?.modifiers?.includes('scrimDark'),
    backgroundColor: section?.modifiers?.includes('scrimLight')
      ? theme.scrim.light
      : section?.modifiers?.includes('scrimDark') && theme.scrim.dark,
    borderRadius: '6px',
  };

  return (
    <PeonBase
      section={section}
      listPeonPromos={(module) => (
        <S.Container $scrim={scrim}>
          <SectionCarousel
            items={module.list.map((item) => ({
              caption: item.body,
              alt: item.asset?.alt,
              primary: item.asset?.primary,
            }))}
            imageProps={{ border: 'none' }}
            contrastColor={
              section.background.textColor === 'Light'
                ? theme.color.white
                : theme.color.black
            }
            scrim={scrim}
            variant={
              section?.modifiers?.includes('reverseContent') ? 'split' : 'stack'
            }
          />
        </S.Container>
      )}
    />
  );
}

CharacterCarousel.bluePrint = [{ listPeonPromos: 'Character Carousel' }];

export default CharacterCarousel;
