import { SpecsTable } from '@local/components/pages/GamingSystems/Switch2/';
import s from './SpecsSection.module.css';
import Constrained from '@local/components/constrained';
import { Spacer, Image, Heading } from '@nintendo-of-america/component-library';
import DeepLinkFarm from './DeepLinkFarm';

const SpecsSection = ({ modules: tables, title }) => {
  return (
    <div>
      <div className={s.header}>
        <Heading className={s.headerText}>{title}</Heading>
      </div>
      <Constrained>
        <DeepLinkFarm tables={tables} className={s.pills} />
        {tables.map((table) => (
          <div key={table?.heading} className={s.table} id={table?.deepLink}>
            <div className={s.tableHeaderContainer}>
              <h2 className={s.title}>{table?.heading}</h2>
              <div className={s.image}>
                <Image
                  assetPath={table?.modules?.[0]?.primary?.assetPath}
                  alt={table?.moudles?.[0]?.alt}
                />
              </div>
            </div>
            <hr className={s.line} />
            <Spacer size={24} />
            <SpecsTable rows={table?.modules.slice(1)} />
          </div>
        ))}
        <Spacer size={64} />
      </Constrained>
    </div>
  );
};

export default SpecsSection;
