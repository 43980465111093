import { RichText, AccordionRow } from '@local/components';
import { responsiveAssetPath } from '@local/lib/helpers';
import * as S from './FAQ.styles';
import PeonBase from '../PeonBase';

function FAQ({ section }) {
  const reverseContent = section.modifiers.includes('reverseContent');
  const textCenter = section.modifiers.includes('textCenter');

  return (
    <PeonBase
      section={section}
      listPeonPromos={(module, i) => (
        <div>
          {module.list.map((faqItem) => {
            return (
              <AccordionRow
                heading={faqItem.body?.content[0]?.content[0]?.value}
                rounded
                key={`FAQItem ${i}`}
              >
                <div>
                  <RichText
                    data={{ content: faqItem.body?.content?.slice(1) }}
                  />
                </div>
              </AccordionRow>
            );
          })}
        </div>
      )}
      peonPromo={(module) => (
        <S.FAQHeader
          $textCenter={textCenter}
          $reverseContent={reverseContent}
          $hasImage={module.asset?.primary?.assetPath}
        >
          <S.FAQTitle $reverseContent={reverseContent}>
            <RichText data={module.body} headingMargin={false} />
          </S.FAQTitle>
          {module.asset?.primary?.assetPath && (
            <S.HeaderImg
              $reverse={reverseContent}
              assetPath={responsiveAssetPath(module.asset)}
              alt={module.asset?.alt}
            />
          )}
        </S.FAQHeader>
      )}
    />
  );
}

FAQ.bluePrint = [
  { peonPromo: 'FAQ section header' },
  { listPeonPromos: 'FAQ items' },
];

export default FAQ;
