import styled from 'styled-components';
import { CONSTRAINED_MAX_WIDTH } from '@local/components/constrained';

import { m } from 'framer-motion';

export const AnimateLogo = styled(m.div).attrs(({ $disable }) => ({
  initial: { opacity: $disable ? 1 : 0, y: $disable ? 0 : 30 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 1, delay: 0.5, type: 'spring' },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  max-width: ${({ $constrain }) =>
    $constrain ? CONSTRAINED_MAX_WIDTH : 'unset'};
  margin: 0 auto;

  > div:has(img) {
    width: auto;
  }
  ${({ theme }) => theme.mediaQuery.desktop} {
    gap: 2rem;

    > div:last-child {
      order: ${({ $reverseContent }) => ($reverseContent ? -1 : 0)};
    }

    flex-direction: row;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  max-width: 96%;
  ${({ theme }) => theme.mediaQuery.desktop} {
    max-width: 600px;
  }
`;

export const Header = styled.div`
  padding: 1rem 0.5rem;
  text-align: center;

  img {
    object-fit: contain;
    max-width: 96%;
    max-height: 20rem;
    margin: 0 auto;
  }

  h1,
  h2 {
    font-size: 3.75rem;
    text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
    margin: 0;
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    &:nth-child(1) {
      order: 2;
    }
    ${({ theme }) => theme.mediaQuery.tablet} {
      font-size: 5rem;
      flex-direction: row;

      &:nth-child(1) {
        order: 0;
      }
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.font.size.h1LDesktop};
  }
  ${({ theme }) => theme.mediaQuery.tablet} {
    padding: 2rem;
    text-align: inherit;
  }
`;
