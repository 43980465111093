import s from './SpecsTable.module.css';
import RichText from '@local/components/RichText';

const SpecsTable = ({ rows }) => {
  return (
    <div className={s.container}>
      <table className={s.table}>
        <tbody>
          {rows?.map((row) => (
            <tr key={row?.heading}>
              <th>
                <h4 className={s.heading}>{row?.heading}</h4>
              </th>
              <td>
                <RichText data={row?.body} className={s.text} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SpecsTable;
