import { bool, string } from 'prop-types';
import { Video, Image } from '@nintendo-of-america/component-library';
import clsx from 'clsx';
import { MODULE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import s from './FramedVideo.module.css';
import { cloudinaryUrl } from '@shared/util';

const FramedVideo = ({ framePath, asset, stand }) => {
  return (
    <div className={clsx(s.container)}>
      <div
        className={s.frame}
        style={{
          backgroundImage: `url(${cloudinaryUrl({ publicId: framePath })})`,
        }}
      >
        {asset.__contentType === MODULE_TYPES.CLOUDINARY_IMAGE ? (
          <Image assetPath={asset?.primary?.assetPath} alt={asset?.alt} />
        ) : (
          <Video
            autoplay={true}
            controls={false}
            playsInline
            loop
            assetPath={asset?.id || asset?.primary?.assetPath}
          />
        )}
      </div>
      {stand && (
        <Image
          className={s.stand}
          assetPath={cloudinaryUrl({
            publicId: '/ncom/en_US/gaming-systems/switch-2/features/tv-stand',
          })}
          alt={'tv stand'}
        />
      )}
    </div>
  );
};

FramedVideo.propTypes = {
  framePath: string,
  videoPath: string,
  stand: bool,
};

export default FramedVideo;
