import { Button } from '@nintendo-of-america/component-library';
import s from './SpecsSection.module.css';

const DeepLinkFarm = ({ tables }) => {
  return (
    <div className={s.farm}>
      {tables?.map(({ heading, deepLink }) => (
        <Button
          key={heading}
          variant={'secondary'}
          href={`#${deepLink}`}
          className={s.pills}
        >
          {heading}
        </Button>
      ))}
    </div>
  );
};

export default DeepLinkFarm;
