import styled from 'styled-components';
import { IconLink, Image } from '@nintendo-of-america/component-library';

export const OfferContainer = styled.div`
  background: ${({ theme, $scrimDark }) =>
    $scrimDark ? `rgba(0, 0, 0, .4)` : theme.color.white};
  color: ${({ theme, $scrimDark }) =>
    $scrimDark ? theme.color.white : theme.color.darkGray2};
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const OfferGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  align-content: center;
  align-items: start;
  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SingleItem = styled(Image)`
  img {
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.color.lightGray3};
  }
`;

export const OfferItem = styled.div`
  background: darkgray;
  border: 1px dashed gray;
  padding: 2rem;
  color: white;
  border-radius: 6px;
  margin: 0.25rem 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  background-image: ${({ $bg, $dividerColor }) =>
    `${$bg.images ? `url(${$bg.images[0]})` : `linear-gradient(${$dividerColor}, ${$dividerColor})`}`};
  background-position: bottom;
  transform: translate(0, -1px);
  z-index: 1;

  --mask: conic-gradient(
      from -55deg at bottom,
      #0000,
      #000 1deg 109deg,
      #0000 110deg
    )
    50%/28.56px 100%;

  -webkit-mask: var(--mask);
  mask: var(--mask);
`;

export const RetailOffers = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  max-width: 300px;
  margin: 0.5rem auto;
  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    grid-template-columns: repeat(3, 1fr);
    max-width: 450px;
  }
  ${({ theme }) => theme.mediaQuery.desktop} {
    margin: 0.5rem 0;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Retailer = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #c8c8c8;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: opacity 200ms ease-in-out 25ms;
  }

  &:hover {
    &::after {
      opacity: 0.3;
    }
  }
  ${({ theme }) => theme.mediaQuery.tablet} {
    height: 60px;
  }
`;

export const ProductInfo = styled.div`
  em {
    font-style: normal;
    color: #e60012;
    padding: 0.25rem 1rem;
    font-weight: bold;
    background: ${({ theme }) => theme.color.primaryLight};
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  sup {
    font-size: ${({ theme }) => theme.font.size.legalDesktop};
  }
`;

export const ReadMore = styled.div`
  max-height: ${({ expanded }) => `${expanded ? `15rem` : '0'}`};
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
`;

export const StyledIconLink = styled(IconLink)`
  padding-left: 0;
`;
