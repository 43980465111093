import { Image, Button } from '@nintendo-of-america/component-library';
import { MODULE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import { RichText, LinkItem, ContentRating } from '@local/components';
import { responsiveAssetPath } from '@local/lib/helpers';
import PeonBase from '../PeonBase';
import s from './Banner.module.css';
import clsx from 'clsx';

function BannerSection({ section }) {
  const rating =
    section?.modules?.find(
      (type) => type?.__contentType === MODULE_TYPES.CONTENT_RATING
    ) || null;

  return (
    <PeonBase
      section={section}
      contentRating={() => {
        return <></>;
      }}
      peonPromo={(module) => (
        <div
          className={clsx(s.promoBlade, (rating || module.body) && s.shadow)}
        >
          <LinkItem href={module.cta?.url} ariaLabel={module.cta?.label}>
            <Image
              assetPath={responsiveAssetPath(module.asset)}
              alt={module.asset?.alt}
            />
          </LinkItem>
          {(module.body || rating) && (
            <div
              className={clsx(
                s.caption,
                section.modifiers.includes('scrimDark') && s.scrimDark,
                section.modifiers.includes('scrimLight') && s.scrimLight
              )}
            >
              <div className={s.titleAndCta}>
                <div>
                  <RichText data={module.body} headingMargin={false} />
                </div>
                {module.cta && (
                  <Button href={module.cta?.url}>{module.cta?.label}</Button>
                )}
              </div>
              {rating?.contentRating && (
                <ContentRating
                  className={clsx(
                    section.modifiers.includes('scrimDark') && s.lightRating
                  )}
                  gamesShown={rating.gamesShown}
                  rating={rating.contentRating}
                  descriptors={rating.contentDescriptors}
                />
              )}
            </div>
          )}
        </div>
      )}
    />
  );
}

BannerSection.bluePrint = [
  { peonPromo: 'Banner / Promo Banner' },
  { moduleContentRating: '' },
];

export default BannerSection;
