import styled from 'styled-components';
import { cloudinaryUrl } from '@shared/util';

export const Container = styled.div`
  text-align: center;
  color: ${({ $scrimDark, theme }) =>
    $scrimDark ? theme.color.white : theme.color.black};
  background: ${({ $scrimLight, theme }) => $scrimLight && theme.scrim.light};
  background: ${({ $scrimDark, theme }) => $scrimDark && theme.scrim.dark};
  background: ${({ $asset }) =>
    $asset.primary &&
    `url(${cloudinaryUrl({ publicId: $asset?.secondary?.assetPath || $asset?.primary?.assetPath })})`};
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  padding: 2rem;
  display: flex;
  flex-direction: column;

  > div:nth-child(1) {
    flex-basis: ${({ $hasImage }) => $hasImage && '33%'};

    img {
      max-height: 120px;
      max-width: 120px;
      margin: 0 auto;
    }
  }

  gap: 2rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme }) => theme.mediaQuery.tablet} {
    text-align: ${({ $textCenter }) => ($textCenter ? 'center' : 'left')};
    flex-direction: row;
    background: ${({ $asset }) =>
      $asset.primary &&
      `url(${cloudinaryUrl({ publicId: $asset?.primary?.assetPath })})`};
  }
`;
