import PeonBase from '../PeonBase';

function HeaderAnimated({ section }) {
  return <PeonBase section={section} />;
}

HeaderAnimated.bluePrint = [{ carouselSlide: 'Animation Overlay' }];
HeaderAnimated.fullWidth = true;

export default HeaderAnimated;
