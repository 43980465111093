import styled from 'styled-components';
import { Image } from '@nintendo-of-america/component-library';

export const FAQHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.color.primary};
  padding-bottom: 1rem;
  text-align: ${({ $textCenter, $hasImage, $reverseContent }) =>
    $textCenter && !$hasImage ? 'center' : $reverseContent ? 'right' : 'left'};
`;

export const FAQTitle = styled.div`
  flex: 1;
`;

export const HeaderImg = styled(Image)`
  max-width: 200px;
  order: ${({ $reverse }) => ($reverse ? -1 : 1)};
`;
