import ImageDescriptionItem from './ImageDescriptionItem';
import s from './ImageDescriptionGrid.module.css';

/**
 * ImageDescriptionGrid
 *
 * @param {Object} module - Module: Section object containing modules containing array of Module: Promo C's
 * @example
 * // example argument
 * const module = {
 *  modules: [
 *      {Promo C object},
 *      {another Promo C object}
 *  ]
 * }
 */

const ImageDescriptionGrid = ({ module }) => {
  const modules = module?.modules;
  return (
    <div className={s.grid}>
      {modules?.map((item) => (
        <ImageDescriptionItem key={item?.heading} item={item} />
      ))}
    </div>
  );
};

export default ImageDescriptionGrid;
