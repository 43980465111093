import { CATEGORY } from './constants';
import ProxyLink from './ProxyLink';

const baseColors = {
  red600: '#E60012',
  red700: '#AC000D',
  blue50: '#E8EBFC',
  blue200: '#B2BBF0',
  blue500: '#4B5CCE',
  blue600: '#3946A0',
  blue800: '#2A3477',
  green100: '#E1F7D6',
  green700: '#2D8513',
  yellow300: '#FFFBB1',
  yellow700: '#BEA000',
  violet600: '#9531B9',
  gray50: '#F8F8F8',
  gray100: '#EFEFEF',
  gray200: '#DADADA',
  gray300: '#C8C8C8',
  gray400: '#969696',
  gray500: '#727272',
  gray600: '#484848',
  gray800: '#242424',
  white: '#ffffff',
  black: '#000000',
};

export default {
  name: 'Nintendo Light Theme',
  slug: 'nintendo-light-theme',
  category: CATEGORY.GENERAL,
  description: 'This is the default theme used on nintendo.com',
  color: {
    primary: '#e60012',
    primaryHover: '#ac000d',
    primaryLight: '#fde6e7',
    secondary: '#3946a0',
    secondaryHover: '#2a3477',
    secondaryLight: '#bdc3e8',
    black: '#000000',
    white: '#ffffff',
    darkGray1: '#242424',
    darkGray2: '#484848',
    darkGray3: '#727272',
    darkGray4: '#969696',
    lightGray1: '#c8c8c8',
    lightGray2: '#dadada',
    lightGray3: '#efefef',
    lightGray4: '#f8f8f8',
  },
  colors: {
    ui: {
      bgMain: baseColors.white,
      bgAccent: baseColors.gray50,
      line: baseColors.gray200,
      accent: baseColors.red600,
    },
    text: {
      standard: baseColors.gray600,
      link: baseColors.red600,
      inverse: baseColors.white,
    },
    button: {
      background: {
        primary: baseColors.red600,
        primaryHover: baseColors.red700,
        secondary: baseColors.blue600,
        secondaryHover: baseColors.blue700,
        tertiary: baseColors.white,
        tertiaryHover: baseColors.red50,
        tertiarySelected: baseColors.red600,
        quaternary: baseColors.white,
        quaternaryHover: baseColors.gray100,
        quaternarySelected: baseColors.blue600,
        ghost: 'rgba(0,0,0, 0.25)',
        ghostHover: 'rgba(0,0,0, 0.5)',
        ghostFocus: 'rgba(255,255,255, 0.25)',
        disabled: baseColors.gray200,
      },
      content: {
        primary: baseColors.white,
        secondary: baseColors.white,
        tertiary: baseColors.red600,
        tertiarySelected: baseColors.white,
        quaternary: baseColors.blue600,
        quaternarySelected: baseColors.white,
        ghost: baseColors.white,
      },
      stroke: {
        tertiary: baseColors.red600,
        quaternary: baseColors.blue600,
        ghost: baseColors.white,
      },
    },
    icon: {
      standard: baseColors.gray600,
      primary: baseColors.red600,
      inverse: baseColors.white,
    },
    descriptionTag: {
      red: baseColors.red600,
      blue: baseColors.blue500,
      violet: baseColors.violet600,
      charcoal: baseColors.gray600,
      text: baseColors.white,
    },
    alert: {
      text: baseColors.gray600,
      errorBg: baseColors.red50,
      errorIcon: baseColors.red600,
      warningBg: baseColors.yellow300,
      warningIcon: baseColors.yellow700,
      successBg: baseColors.green100,
      successIcon: baseColors.green700,
      attentionBg: baseColors.blue50,
      attentionIcon: baseColors.blue600,
      maintenanceBg: baseColors.gray100,
    },
  },
  borderRadius: 'var(--border-radius)',
  borderRadiusSmall: 'var(--border-radius-small)',
  borderRadiusRound: '1rem', // 16px
  pxToRem: (input) => `${input / 16}rem`, // input a number in px to return in rem
  font: {
    lineHeight: '1.4',
    family: "'museo-sans', -apple-system, BlinkMacSystemFont, sans-serif",
    weight: {
      bold: 700,
      light: 300,
    },
    size: {
      h1LDesktop: '2.375rem', // 38px
      h1SDesktop: '1.75rem', // 28px
      h2Desktop: '1.3125rem', // 21px
      h3Desktop: '1.125rem', // 18px
      h4Desktop: '1rem', // 16px
      titleDesktop: '1rem', // 16px
      bodyDesktop: '1rem', // 16px
      captionDesktop: '0.875rem', // 14px
      legalDesktop: '0.75rem', //12px
      h1LMobile: '1.75rem', // 28px
      h1SMobile: '1.3125rem', // 21px
      h2Mobile: '1.125rem', // 18px
      h3Mobile: '1rem', // 16px
      h4Mobile: '0.875rem', // 14px
      titleMobile: '0.875rem', // 14px
      bodyMobile: '0.875rem', // 14px
      captionMobile: '0.75rem', // 12px
      legalMobile: '0.625rem', //10px
    },
  },
  spacing: {
    0: '0rem',
    2: '0.125rem', // 2px
    4: '0.25rem', // 4px
    8: '0.5rem', // 8px
    12: '0.75rem', // 12px
    16: '1rem', // 16px
    20: '1.25rem', // 20px
    24: '1.5rem', // 24px
    28: '1.75rem', // 28px
    32: '2rem', // 32px
    36: '2.25rem', // 36px
    40: '2.5rem', // 40px
    44: '2.75rem', // 44px
    48: '3rem', // 48px
    64: '4rem', // 64px
    80: '5rem', // 80px
    96: '6rem', // 96px
    112: '7rem', // 112px
    128: '8rem', // 128px
  },
  page: {
    maxWidth: '90rem', // 1440px
    slimMaxWidth: '71.5rem', // 1144px
  },
  image: {
    mobile: {
      xxsmall: {
        width: '7.5rem',
        height: '4.25rem',
      },
    },
    desktop: {
      xxsmall: {
        width: '10rem',
        height: '5.5rem',
      },
    },
  },
  mediaQuery: {
    // One pixel up from largest iphone size to ensure those phones have the
    // correct layout
    tabletSmall: '@media (min-width: 431px)',
    tablet: '@media (min-width: 768px)',
    // One pixel up from ipad pro width
    desktop: '@media (min-width: 1025px)',
    desktopLarge: '@media (min-width: 1440px)',
  },
  scrim: {
    light: 'rgba(255,255,255,0.6)',
    dark: 'rgba(0,0,0,0.6)',
  },
  movement: {
    duration: '200ms',
    easing: 'ease-in-out',
    delay: '25ms',
  },
  skeleton: {
    image: 'star-play-nintendo',
    icon: 'star-play-nintendo',
  },
  ProxyLink,
  animation: {
    button: {
      hover: {
        scaleX: 1.05,
        scaleY: 1.05,
        transition: {
          scaleX: { type: 'spring', stiffness: 600 },
          scaleY: { type: 'spring', stiffness: 600 },
        },
      },
      tap: {
        scaleX: 0.99,
        scaleY: 0.99,
        transition: {
          ease: 'anticipate',
          type: 'tween',
          duration: 0.1,
        },
      },
    },
    vertical: {
      standardBounce: {
        type: 'spring',
        stiffness: 284,
        damping: 19.0,
      },
      fastBounce: {
        type: 'spring',
        stiffness: 645,
        damping: 25,
      },
      gentleBounce: {
        type: 'spring',
        stiffness: 178,
        damping: 18.3,
      },
      standardEase: {
        type: 'spring',
        stiffness: 177.8,
        damping: 20,
      },
      fastEase: {
        type: 'spring',
        stiffness: 1024,
        damping: 48,
      },
    },
    horizontal: {
      standardBounce: {
        type: 'spring',
        stiffness: 284,
        damping: 19.0,
      },
      fastBounce: {
        type: 'spring',
        stiffness: 729,
        damping: 24,
      },
      standardEase: {
        type: 'spring',
        stiffness: 80.1,
        damping: 13.4,
      },
      fastEase: {
        type: 'spring',
        stiffness: 401.4,
        damping: 30,
      },
    },
  },
};
