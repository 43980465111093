import { FullBleedPageHero } from '@nintendo-of-america/component-library';
import PeonBase from '../PeonBase';

function HeaderFullBLeed({ section }) {
  return (
    <PeonBase
      section={section}
      peonPromo={(module) => (
        <FullBleedPageHero
          heading={module?.body?.content[0]?.content[0]?.value}
          subtitle={module?.body?.content[1]?.content[0]?.value}
          image={{
            desktop: {
              assetPath: module?.asset?.primary?.assetPath,
            },
            mobile: {
              assetPath:
                module?.asset?.secondary?.assetPath ??
                module?.asset?.primary?.assetPath,
            },
            alt: module?.body?.content[0]?.content[0]?.value,
          }}
          pillFarm={module?.ctas}
          onPillClick={module?.onPillClick}
          pillProps={module?.pillProps}
          icon={module?.asset?.alt}
        />
      )}
    />
  );
}

HeaderFullBLeed.bluePrint = [{ peonPromo: '' }];
HeaderFullBLeed.fullWidth = true;

export default HeaderFullBLeed;
