import { node, bool, string, array, object } from 'prop-types';
import {
  Image,
  IconLink,
  ChevronRightIcon,
  HamburgerMenuIcon,
  Heading,
  XIcon,
  FocusBoundary,
} from '@nintendo-of-america/component-library';
import { CloudinaryAsset } from '@local/components';
import { Constrained } from '@local/components';
import clsx from 'clsx';
import s from './Switch2Wrapper.module.css';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { LazyMotion, m, domAnimation } from 'framer-motion';
import { MODULE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import { useLocalizer } from '@nintendo-of-america/react-hooks';

const Switch2Wrapper = (props) => {
  const {
    className,
    children,
    hideHeader,
    hideSubHeader,
    hideFooter,
    headerEntries,
    footerEntries,
    headerModule,
    float,
  } = props;
  const { text } = useLocalizer();
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const clickHandler = () => {
    setHamburgerMenuOpen(false);
  };

  const headerText = useMemo(() => {
    return headerModule?.heading;
  }, [headerModule]);

  const headerImage = useMemo(() => {
    return headerModule?.modules?.[0]?.asset?.primary?.assetPath;
  }, [headerModule]);

  const heroAsset = useMemo(() => {
    return headerModule?.modules?.[1];
  }, [headerModule]);

  const hasHeroAsset = useMemo(() => {
    const heroModule = headerModule?.modules?.[1];
    if (heroModule?.__contentType === MODULE_TYPES.CLOUDINARY_VIDEO) {
      if (heroModule?.id) return true;
    }
    if (heroModule?.__contentType === MODULE_TYPES.CLOUDINARY_IMAGE) {
      if (heroModule?.primary?.assetPath) return true;
    }
    return false;
  }, [headerModule]);

  const headerLinks = useMemo(() => {
    return headerEntries?.modules?.[0]?.modules?.map((entry) => {
      return {
        assetPath: entry?.asset?.primary?.assetPath,
        label: entry?.cta?.label,
        url: entry?.cta?.url,
        alt: entry?.asset?.alt || '',
      };
    });
  }, [headerEntries]);

  const headerElements = useMemo(() => {
    return headerLinks?.map((link) => {
      return (
        <div className={s.headerLink} key={link?.label} tabIndex={0} role="tab">
          <a className={s.flexLink} href={link?.url}>
            <Image
              className={s.headerLinkImage}
              assetPath={link?.assetPath}
              alt={link?.alt}
            />{' '}
            <Heading variant="h3">{link?.label}</Heading>
          </a>
        </div>
      );
    });
  }, [headerLinks]);

  const footerLinks = useMemo(() => {
    const imageLinks = footerEntries?.modules?.[1]?.modules;
    const iconLinks = footerEntries?.modules?.[2]?.modules;

    return {
      imageLinks: {
        bigImage: {
          assetPath: imageLinks?.[0]?.asset?.primary?.assetPath,
          label: imageLinks?.[0]?.cta?.label,
          url: imageLinks?.[0]?.cta?.url,
          alt: imageLinks?.[0]?.asset?.alt || '',
        },
        topRow: [
          {
            assetPath: imageLinks?.[1]?.asset?.primary?.assetPath,
            label: imageLinks?.[1]?.cta?.label,
            url: imageLinks?.[1]?.cta?.url,
            alt: imageLinks?.[1]?.asset?.alt || '',
          },
          {
            assetPath: imageLinks?.[2]?.asset?.primary?.assetPath,
            label: imageLinks?.[2]?.cta?.label,
            url: imageLinks?.[2]?.cta?.url,
            alt: imageLinks?.[2]?.asset?.alt || '',
          },
        ],
        bottomImage: {
          assetPath: imageLinks?.[3]?.asset?.primary?.assetPath,
          label: imageLinks?.[3]?.cta?.label,
          url: imageLinks?.[3]?.cta?.url,
          alt: imageLinks?.[3]?.asset?.alt || '',
        },
      },
      iconLinks: iconLinks?.map((iconLink) => {
        return {
          label: iconLink?.label,
          url: iconLink?.url,
        };
      }),
    };
  }, [footerEntries]);

  useEffect(() => {
    document
      ?.getElementById('click-container')
      ?.addEventListener('click', clickHandler);
    return () => {
      document
        ?.getElementById('click-container')
        ?.removeEventListener('click', clickHandler);
    };
  }, []);

  const handleMenuButtonClick = useCallback(() => {
    setHamburgerMenuOpen(!hamburgerMenuOpen);
  }, [setHamburgerMenuOpen, hamburgerMenuOpen]);

  return (
    <LazyMotion features={domAnimation}>
      {!hideHeader && (
        <>
          <div
            id="click-container"
            className={clsx(
              s.clickContainer,
              !hamburgerMenuOpen && s.noPointerEvents
            )}
          />
          <FocusBoundary bindFocus={hamburgerMenuOpen}>
            <nav
              className={clsx(s.subNav, float && s.float)}
              aria-label="Secondary navigation"
            >
              <div className={s.subConstrain}>
                <div className={clsx(s.menuLogo, float && s.hide)}>
                  <Image
                    assetPath={
                      '/ncom/en_US/gaming-systems/switch-2/features/logo'
                    }
                    alt={''}
                  />
                </div>
                <button
                  onClick={handleMenuButtonClick}
                  className={s.menuBtn}
                  aria-label={hamburgerMenuOpen ? text('Close') : text('Open')}
                >
                  {hamburgerMenuOpen ? (
                    <m.div
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                    >
                      <XIcon />
                    </m.div>
                  ) : (
                    <m.span
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                    >
                      <HamburgerMenuIcon />
                    </m.span>
                  )}
                  <span className={s.menuText}>Menu</span>
                </button>
              </div>

              <m.div
                className={clsx(s.subNavLinkContainer)}
                variants={{
                  show: {
                    opacity: 1,
                    scale: 1,
                    visibility: 'visible',
                    transform: 'translateY(72px)',
                  },
                  hide: {
                    opacity: 0,
                    scale: 0.7,
                    visibility: 'hidden',
                    transform: 'translateY(-120px)',
                  },
                }}
                animate={hamburgerMenuOpen ? 'show' : 'hide'}
                aria-hidden={!hamburgerMenuOpen}
                role="tablist"
              >
                {headerElements}
              </m.div>
            </nav>
          </FocusBoundary>
          {!float && !hideSubHeader && (
            <div className={clsx(s.header, !hasHeroAsset && s.extraPadding)}>
              <Constrained>
                <div className={s.pageTitle}>
                  <div className={s.pageTitleContent}>
                    {headerImage && (
                      <Image
                        assetPath={headerImage}
                        alt={''}
                        className={s.pageTitleIcon}
                      />
                    )}
                    <div>
                      <Heading variant="h1" large>
                        {headerText}
                      </Heading>
                    </div>
                  </div>
                </div>
                {hasHeroAsset && (
                  <div className={s.titleImg}>
                    <CloudinaryAsset
                      asset={heroAsset}
                      alt={heroAsset?.alt || ''}
                    />
                  </div>
                )}
              </Constrained>
            </div>
          )}
        </>
      )}
      <div className={clsx(className, s.page)}>{children}</div>
      {!hideFooter && (
        <div className={s.footer}>
          <Constrained small>
            <a href={'/'}>
              <Image
                assetPath={
                  footerEntries?.modules?.[0]?.modules?.[0]?.primary?.assetPath
                }
                alt={footerEntries?.modules?.[0]?.modules?.[0]?.alt}
                className={s.logoImage}
              ></Image>
            </a>
          </Constrained>
          <Constrained small>
            <div className={s.moreGrid}>
              <a
                href={footerLinks?.imageLinks?.bigImage?.url}
                className={clsx(s.gridItem, s.gridThird, s.mobileFlex)}
              >
                <Image
                  assetPath={footerLinks?.imageLinks?.bigImage?.assetPath}
                  alt={footerLinks?.imageLinks?.bigImage?.alt}
                />
                <Heading variant="h2">
                  {footerLinks?.imageLinks?.bigImage?.label}
                </Heading>
              </a>
              <a
                className={s.gridItem}
                href={footerLinks?.imageLinks?.topRow?.[0]?.url}
              >
                <Image
                  assetPath={footerLinks?.imageLinks?.topRow?.[0]?.assetPath}
                  alt={footerLinks?.imageLinks?.topRow?.[0]?.alt}
                  className={s.rowImage}
                />
                <Heading variant="h2">
                  {footerLinks?.imageLinks?.topRow?.[0]?.label}
                </Heading>
              </a>
              <a
                className={s.gridItem}
                href={footerLinks?.imageLinks?.topRow?.[1]?.url}
              >
                <Image
                  assetPath={footerLinks?.imageLinks?.topRow?.[1]?.assetPath}
                  alt={footerLinks?.imageLinks?.topRow?.[1]?.alt}
                />
                <Heading variant="h2">
                  {footerLinks?.imageLinks?.topRow?.[1]?.label}
                </Heading>
              </a>
              <a
                className={clsx(s.gridItem, s.double, s.mobileFlex)}
                href={footerLinks?.imageLinks?.bottomImage?.url}
              >
                <Image
                  assetPath={footerLinks?.imageLinks?.bottomImage?.assetPath}
                  alt={footerLinks?.imageLinks?.bottomImage?.alt}
                />
                <Heading variant="h2">
                  {footerLinks?.imageLinks?.bottomImage?.label}
                </Heading>
              </a>
            </div>
          </Constrained>
          <Constrained small>
            <div className={s.linkGrid}>
              {footerLinks?.iconLinks?.map((iconLink) => (
                <div className={s.iconLinkContainer} key={iconLink?.label}>
                  <IconLink
                    icon={ChevronRightIcon}
                    href={iconLink?.url}
                    className={s.iconLink}
                  >
                    {iconLink?.label}
                  </IconLink>
                </div>
              ))}
            </div>
          </Constrained>
        </div>
      )}
    </LazyMotion>
  );
};

Switch2Wrapper.propTypes = {
  className: string,
  children: node,
  hideHeader: bool,
  hideSubHeader: bool,
  hideFooter: bool,
  headerEntries: array,
  footerEntries: array,
  headerModule: object,
  float: bool,
};

export default Switch2Wrapper;
