import { Button } from '@nintendo-of-america/component-library';
import PeonBase from '../PeonBase';
import * as S from './SubNav.styles';

function SubNav({ section }) {
  const reverseContent = section.modifiers.includes('reverseContent');

  return (
    <PeonBase
      section={section}
      listCtas={(module) => (
        <S.PillContainer $reverseContent={reverseContent}>
          {module.list.map(
            (item) =>
              shouldShow(item) && (
                <S.ButtonContainer key={item.label}>
                  <Button round href={item.url} size={'small'}>
                    {item.label}
                  </Button>
                </S.ButtonContainer>
              )
          )}
        </S.PillContainer>
      )}
    ></PeonBase>
  );
}

function shouldShow(item) {
  return item.label && item.label != 'null';
}

SubNav.bluePrint = [{ listModuleCtas: '' }];
SubNav.fullWidth = true;

export default SubNav;
