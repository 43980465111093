import defaultTheme from './default';
import { CATEGORY } from './constants';

export default {
  ...defaultTheme,
  name: 'Pikmin',
  slug: 'pikmin',
  category: CATEGORY.IP,
  description: 'Pikmin theme',
  color: {
    ...defaultTheme.color,
    primary: '#00B400',
    primaryHover: '#006535',
    primaryLight: '#FFFFFF',
    secondary: '#FAB400',
    secondaryHover: '#826400',
    secondaryLight: '#E6E600',
  },
};
