import { useEffect, useState } from 'react';
import { object, number } from 'prop-types';
import { Image, Video } from '@nintendo-of-america/component-library';
import clsx from 'clsx';
import s from './Switch2Video.module.css';
import { MODULE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useMatchMedia } from '@nintendo-of-america/react-hooks/';
import { cloudinaryUrl } from '@shared/util';
const Switch2Video = ({
  leftJoycon,
  frameAsset,
  rightJoycon,
  video,
  docked,
  // width,
}) => {
  const [xOffset, setXOffset] = useState('0');
  const [rotate, setRotate] = useState('0');
  const isDesktop = useMatchMedia('(min-width: 768px)');
  useEffect(() => {
    setXOffset(isDesktop ? '200px' : '8px');
    setRotate(isDesktop ? '55deg' : '15deg');
  }, [isDesktop]);

  return (
    <LazyMotion features={domAnimation}>
      <div className={s.switch}>
        <m.div
          className={clsx(s.joycon, s.left)}
          style={{ x: '-' + xOffset, rotate: '-' + rotate }}
          whileInView={{
            x: '17px',
            rotate: '0',
            transition: {
              duration: 1.2,
              delay: '.15',
              type: 'spring',
              bounce: 0.1,
            },
          }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Image
            assetPath={leftJoycon.primary.assetPath}
            alt={leftJoycon.alt}
          />
        </m.div>
        <m.div
          className={s.console}
          style={{
            backgroundImage: `url(${cloudinaryUrl({ publicId: frameAsset.primary.assetPath })})`,
          }}
        >
          {!docked && (
            <div className={s.video}>
              {video.__contentType === MODULE_TYPES.CLOUDINARY_IMAGE ? (
                <Image assetPath={video?.primary?.assetPath} alt={video?.alt} />
              ) : (
                <Video
                  autoplay={true}
                  controls={false}
                  playsInline
                  loop
                  assetPath={video.primary.assetPath}
                />
              )}
            </div>
          )}
        </m.div>
        <m.div
          className={clsx(s.joycon, s.right)}
          style={{ x: xOffset, rotate: rotate }}
          whileInView={{
            x: '-17px',
            rotate: '0',
            transition: {
              duration: 1.2,
              delay: '.15',
              type: 'spring',
              bounce: 0.1,
            },
          }}
          viewport={{ once: true }}
        >
          <Image
            assetPath={rightJoycon.primary.assetPath}
            alt={rightJoycon.alt}
          />
        </m.div>
      </div>
    </LazyMotion>
  );
};

Switch2Video.propTypes = {
  leftJoycon: object,
  frameAsset: object,
  rightJoycon: object,
  detach: number,
  video: object,
  width: number,
};

export default Switch2Video;
