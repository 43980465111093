import s from './ShareLinkSection.module.css';
import {
  Button,
  ChevronRightIcon,
  Image,
  Spacer,
} from '@nintendo-of-america/component-library';
import RichText from '@local/components/RichText';
import clsx from 'clsx';

const ShareLinkCard = ({ item }) => {
  return (
    <div className={s.item}>
      <p className={s.subheading}>{item?.asset?.caption}</p>
      <h2 className={s.heading}>{item.heading}</h2>
      <div className={s.line} />
      <Spacer size={32} />
      <Image
        assetPath={item?.asset?.primary?.assetPath}
        alt={item?.asset?.alt}
      />
      <RichText className={s.textSize} data={item?.body?.content?.[0]} />
      {item?.cta?.label && (
        <Button className={clsx(s.textSize, s.button)} href={item?.cta?.url}>
          {item?.cta?.label}
          <ChevronRightIcon width={18} />
        </Button>
      )}
    </div>
  );
};

export default ShareLinkCard;
