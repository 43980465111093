import { RichText } from '@local/components';
import { Image, Carousel } from '@nintendo-of-america/component-library';
import s from './GamechatCarousel.module.css';

/**
 * Gamechat Carousel
 *
 * @param {Object} module - Module: Section object containing modules containing array of Module: Promo C's
 * @example
 * // example argument
 * const module = {
 *  modules: [
 *      {Promo C object},
 *      {another Promo C object}
 *  ]
 * }
 */

const GamechatCarousel = ({ module }) => {
  const modules = module?.modules;
  return (
    <div className={s.carousel}>
      <Carousel>
        {modules.map((item) => (
          <div className={s.slide} key={item?.heading}>
            <Image
              assetPath={item?.asset?.primary?.assetPath}
              alt={item?.asset?.alt}
            />
            {item.body && (
              <div>
                <h3>{item?.heading}</h3>
                <RichText data={item?.body} />
              </div>
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default GamechatCarousel;
