import styled from 'styled-components';

export const PeonSection = styled.section`
  position: relative;
  content-visibility: visible;
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  text-align: ${({ $textCenter }) => ($textCenter ? 'center' : 'left')};
  font-weight: bold;
  background: ${({ $background }) => $background?.color};
  background-repeat: ${({ $background }) => $background?.repeat ?? 'no-repeat'};
  background-size: ${({ $background }) =>
    $background?.repeat === 'no-repeat'
      ? 'contain'
      : $background?.repeat
        ? 'auto'
        : 'cover'};
  background-position: ${({ $background }) =>
    $background?.position ? $background.position : 'center'};
  background-image: ${({ $background }) =>
    $background?.images != null
      ? `url(${$background?.images[$background?.images?.length - 1]})`
      : ``};
  color: ${({ theme, $background }) =>
    $background?.textColor === 'Light'
      ? theme.color.white
      : theme.color.darkGray1};
  padding: ${({ $fullWidth, $paddingAlt }) =>
    $fullWidth ? '0' : $paddingAlt ? '5rem 1rem' : '2rem 1rem'};
  padding-bottom: ${({ $paddingBottom, $fullWidth, $paddingAlt }) =>
    $fullWidth
      ? Math.max(0, $paddingBottom) + 'px'
      : $paddingAlt
        ? Math.max(80, $paddingBottom) + 'px'
        : Math.max(32, $paddingBottom) + 'px'};

  ${({ theme }) => theme.mediaQuery.tablet} {
    background-image: ${({ $background }) =>
      $background?.images != null ? `url(${$background?.images[0]})` : ``};
    background-attachment: ${({ $background }) =>
      $background?.parallax ? 'fixed' : 'inherit'};
  }

  p:empty {
    display: none;
  }
`;

export const BluePrintSection = styled.section`
  border: 1px dashed blue;
  margin: 2rem;
  padding: 1rem;
  border-radius: 6px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1rem;
  background-color: #dae4f2;
`;

export const Item = styled.div`
  background: ${({ $required }) => ($required ? '#c2dbfc' : '#dae4fa')};
  border: 1px ${({ $required }) => ($required ? 'solid' : 'dashed')} blue;
  text-align: center;
  padding: 1rem;
  max-width: 400px;
  margin: 0.5rem auto;
  border-radius: 6px;

  span {
    font-weight: normal;
    font-size: small;
  }
`;

export const ChildContainer = styled.div`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '96%')};
  max-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '71.5rem')};
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
`;

export const FloatDivider = styled.div`
  height: ${({ $height }) => $height}px;
  width: 100%;
  top: 100%;
  left: 50%;
  background-image: ${({ $imgDesktop, $imgMobile }) =>
    `url(${$imgMobile ? $imgMobile : $imgDesktop})`};
  background-position: ${({ $position }) => ($position ? $position : 'center')};
  background-size: ${({ $repeat }) => ($repeat ? 'contain' : 'cover')};
  background-repeat: ${({ $repeat }) =>
    $repeat === 'repeat' ? 'repeat-x' : 'no-repeat'};
  z-index: 1;
  position: absolute;
  transform: translate(
    -50%,
    ${({ $position }) =>
      $position === 'top' ? ' 0' : $position == 'bottom' ? ' -100%' : ' -50%'}
  );
  ${({ theme }) => theme.mediaQuery.tablet} {
    background-image: ${({ $imgDesktop }) => `url(${$imgDesktop})`};
  }
`;
