import s from './CameraDisplay.module.css';
import {
  Button,
  ChevronRightIcon,
} from '@nintendo-of-america/component-library';
import RichText from '@local/components/RichText';
import { Image } from '@nintendo-of-america/component-library';

const CameraDisplay = ({ module }) => {
  const modules = module;
  return (
    <div className={s.card}>
      {/* Mobile Image */}
      <div className={s.cameraMobile}>
        <Image
          className={s.cameraMobileImage}
          assetPath={modules?.modules?.[0]?.asset?.primary?.assetPath}
          alt={modules?.module?.[0]?.asset?.alt}
        />
      </div>
      {/* Desktop Image */}
      <Image
        className={s.cameraDesktop}
        assetPath={modules?.modules?.[0]?.asset?.primary?.assetPath}
        alt={modules?.module?.[0]?.asset?.alt}
      />
      <div className={s.padding}>
        <p className={s.label}>{modules.heading}</p>
        <h2 className={s.heading}>{modules?.modules?.[0]?.heading}</h2>
        <h3 className={s.comingSoon}>
          {modules?.modules?.[0]?.asset?.caption}
        </h3>
      </div>
      <hr className={s.rounded} />
      <div className={s.padding}>
        <RichText className={s.text} data={modules?.modules?.[0]?.body} />
        {modules?.modules?.[0].cta?.label && (
          <Button className={s.button}>
            {modules?.modules?.[0].cta?.label}
            <ChevronRightIcon size={18} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default CameraDisplay;
