import { Heading } from '@nintendo-of-america/component-library';
import s from './LeftBorderTitle.module.css';

/**
 * Left Border Title
 *
 * @param {String} heading
 * @param {boolean} subheading - (optional)
 *
 */

const LeftBorderTitle = ({ heading, subheading }) => {
  return (
    <div className={s.container}>
      <Heading variant="h2" className={s.heading}>
        {heading}
      </Heading>
      {subheading && <p>{subheading}</p>}
    </div>
  );
};

export default LeftBorderTitle;
