import { useState } from 'react';
import { Image, Button } from '@nintendo-of-america/component-library';
import { RichText } from '@local/components';
import { responsiveAssetPath } from '@local/lib/helpers';
import PeonBase from '../PeonBase';
import * as S from './MiniBlade.styles';

function MiniBladeSection({ section, productProps }) {
  const [background, setBackground] = useState({});
  return (
    <PeonBase
      section={section}
      peonPromo={(module) => (
        <S.Container
          $hasImage={module?.asset?.primary?.assetPath !== undefined}
          $scrimLight={section.modifiers.includes('scrimLight')}
          $scrimDark={section.modifiers.includes('scrimDark')}
          $textCenter={section.modifiers?.includes('textCenter')}
          $asset={background}
        >
          {module?.asset?.primary?.assetPath && (
            <Image
              assetPath={responsiveAssetPath(module.asset)}
              alt={module.asset?.alt}
            />
          )}
          <div>
            <RichText
              data={module.body}
              headingMargin={false}
              productProps={productProps}
            />
          </div>
          {module.cta && (
            <Button href={module.cta?.url}>{module.cta?.label}</Button>
          )}
        </S.Container>
      )}
      cloudinaryAsset={(module) => {
        setBackground(module);
        return <></>;
      }}
    />
  );
}

MiniBladeSection.bluePrint = [
  { peonPromo: 'Mini Blade' },
  { moduleCloudinaryAsset: 'Background?' },
];

export default MiniBladeSection;
